import React from "react";
import { connect } from "react-redux";
import CentralPaymentIframe from "./CentralPaymentIframe";
import { closeCpModal } from "../../../store/centralPaymentSlice";
import ReactModal from 'react-modal';
import { max } from "moment";


class CentralPaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeader: true,
    };
  }

  componentDidMount() {
    console.log('CentralPaymentModal componentDidMount');
    // window.addEventListener("message", this.handlePostMessage);
  }

  componentWillUnmount() {
    // window.removeEventListener("message", this.handlePostMessage);
    document.body.style.overflow = "auto";
  }



  closeCentralPayment = () => {
    this.props.dispatch(closeCpModal());
  };

  getModalStyles = () => {
    switch (this.props.modalType) {
      case "deposit":
        return {
          title: "存款",
        };
      case "withdrawal":
        return {
          title: "提款",
        };
      default:
        return {
          title: "操作",
        };
    }
  };

  getModalContent = () => {
    switch (this.props.modalType) {
      case "deposit":
        return <CentralPaymentIframe type="deposit" onIframeLoaded={this.hideHeader} />;
      case "withdrawal":
        return <CentralPaymentIframe type="withdrawal" onIframeLoaded={this.hideHeader} />;
      default:
        return null;
    }
  };

  getContentHeight = () => {
    return this.state.showHeader ? "calc(100% - 50px)" : "100%";
  };

  hideHeader = () => {
    this.setState({ showHeader: false });
  };

  render() {
    const { modalType } = this.props;
    const { showHeader } = this.state;
    console.log(' cpModalOpen showHeader:', showHeader);
    if (!modalType) return null;

    const customStyles = {
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        zIndex: 1000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
      },
      content: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        border: "none",
        background: "#fff",
        overflow: "hidden",
        borderRadius: 0,
        width: "100vw",
      },
    };

    const modalStyles = this.getModalStyles();

    return (
      <ReactModal
        isOpen={true}
        onRequestClose={this.closeCentralPayment}
        style={customStyles}
        contentLabel={modalStyles.title}
        ariaHideApp={false}
      >
        {/* 用來填補 iframe 初始化完成前的空白 */}
        {showHeader && (
          <div
            className="modal-header"
            style={{
              backgroundColor: "#FFFFFF",
              color: "white",
              padding: "10px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 style={{ margin: 0 }}>{modalStyles.title}</h3>
            <button
              onClick={this.closeCentralPayment}
              style={{
                background: "none",
                border: "none",
                color: "white",
                fontSize: "24px",
                cursor: "pointer",
              }}
            >
              ×
            </button>
          </div>
        )}

        <div
          style={{
            height: this.getContentHeight(),
            overflow: "hidden",
          }}
        >
          {this.getModalContent()}
        </div>
      </ReactModal>
    );
  }
}

const mapStateToProps = (state) => ({
  modalType: state.centralPayment.modalType,
});

export default connect(mapStateToProps)(CentralPaymentModal);