import { throttle } from '$ACTIONS/util';
import { isGuest } from '$ACTIONS/helper';
import {
    getBonusProgress,
    getAppliedHistory,
} from "../../actions/bonusRequest";
import { bonusActions } from "../bonusSlice";
// import store from "../store";
// const { gamesToFilter } = store.getState().game;

export function setBonusProgressAction(call) {
    return async function (dispatch, getState) {
        if (isGuest()) { return; }
        try {
            dispatch(bonusActions.setUIIsLoading(true));
            const res = await getBonusProgress();
            dispatch(bonusActions.setProgress(res));
            dispatch(bonusActions.setUIIsLoading(false));
            call && call();
        } catch (error) {
            console.error(error.message);
        }
    };
}

/*
export function setBonusApplications() {
    return async function (dispatch, getState) {
        if (isGuest()) { return; }
        try {
            dispatch(bonusActions.setUIIsLoading(true));
            const res = await getBonusApplications();
            dispatch(bonusActions.setApplications(res));
            dispatch(bonusActions.setUIIsLoading(false));
        } catch (error) {
            console.error(error.message);
        }
    };
}
*/

export function setBonusAppliedHistoryAction(dateFrom, dateTo, pageIndex, perPageSize, bonusStatusArray) {
    return async function (dispatch, getState) {
        if (isGuest()) { return; }
        try {
            dispatch(bonusActions.setUIIsLoading(true));
            const res = await getAppliedHistory(
                dateFrom, dateTo, pageIndex, perPageSize, bonusStatusArray
            );
            dispatch(bonusActions.setAppliedHistory(res));
            dispatch(bonusActions.setUIIsLoading(false));
        } catch (error) {
            console.error(error.message);
        }
    };
}