import { ApiPort } from "$ACTIONS/TLCAPI";
import { advget } from "$ACTIONS/TlcRequest";

export const DEFAULT_PARAMS = {
    walletProductGroupId: ''
};

export const MAIN_ID = 1;

// TODO: rename to enumWalletProductGroup while UAT
export const enumWalletProductGroupId = {
    MAIN: {ID:1, piwikName:'Total'},
    SB: {ID:2, piwikName:'Sports/Esports'}, // 体育 / 电竞
    LD: {ID:4, piwikName:'Live Dealer'}, // 真人
    P2P: {ID:24, piwikName: 'P2P/InstantGames/Fishing'}, // 棋牌 / 小游戏
    SLOT: {ID:32, piwikName:'Slots'}, // 游戏
    KENO: {ID:64, piwikName:'Lottery'}, // 彩票
};

export const convertToV1Response = v2res => {
    if (!v2res?.isSuccess) {
        return v2res;
    }
    const v2 = v2res?.result || {};
    const balances = v2.balances || [];
    const ary = [{
            "balance": v2.totalBalance,
            "state": "Available",
            "name": "TotalBal",
            "localizedName": "总余额",
            "category": "TotalBal"
        },
        {
            "balance": balances.find(v=>v.walletProductGroupId==enumWalletProductGroupId.MAIN.ID)?.balance,
            "state": "Available",
            "name": "MAIN",
            "localizedName": "主账户",
            "category": "Main"
        },
        {
            "balance": balances.find(v=>v.walletProductGroupId==enumWalletProductGroupId.SB.ID)?.balance,
            "state": "Available",
            "name": "SB",
            "localizedName": "体育 / 电竞",
            "category": "Sportsbook"
        },
        {
            "balance": balances.find(v=>v.walletProductGroupId==enumWalletProductGroupId.LD.ID)?.balance,
            "state": "Available",
            "name": "LD",
            "localizedName": "真人娱乐场",
            "category": "LiveDealer"
        },
        {
            "balance": balances.find(v=>v.walletProductGroupId==enumWalletProductGroupId.P2P.ID)?.balance,
            "state": "Available",
            "name": "P2P",
            "localizedName": "棋牌 / 小游戏",
            "category": "P2P"
        },
        {
            "balance": balances.find(v=>v.walletProductGroupId==enumWalletProductGroupId.SLOT.ID)?.balance,
            "state": "Available",
            "name": "SLOT",
            "localizedName": "老虎机 / 捕鱼",
            "category": "Slots"
        },
        {
            "balance": balances.find(v=>v.walletProductGroupId==enumWalletProductGroupId.KENO.ID)?.balance,
            "state": "Available",
            "name": "KENO",
            "localizedName": "彩票",
            "category": "Keno"
    }];
    return {isSuccess:true, result: ary};
};

export const MAIN_BALANCE_TEXT = '总余额';

export const isToggleOn = storeBalanceV2Result => storeBalanceV2Result?.isWithdrawalContractBalanceBlocking;

export async function getBalanceV2(walletProductGroupId=DEFAULT_PARAMS.walletProductGroupId) {
    try {
        let url = ApiPort.GETBalance + walletProductGroupId;
        return await advget(url);
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getBalanceWalletGroup() {
    try {
        return await advget(ApiPort.GETBalanceWalletGroup);
    } catch (error) {
        console.error(error);
        return null;
    }
}
