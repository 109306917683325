/*
 * @Author: Alan
 * @Date: 2023-05-09 18:01:54
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-04 22:29:14
 * @Description: 优惠请求
 * @FilePath: /F1-M1-WEB-Code/actions/promotionRequest.js
 */
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import { isGuest } from "$ACTIONS/helper";
import moment from "moment";
import { getStartEndDateByNow } from '$ACTIONS/helper'
import { PromotionList, GetAppliedHistory, GetRebateList } from '$ACTIONS/cmsApi'
import { message } from 'antd';

export const enumPromoBonusVersion = { OLD:1 /*2023*/ , NEW:2 /*2024Q2*/ };

export const objOneWalletCardIcon = {
    SB: ['SB1', 'SB2'],
    LD: ['LD'],
    P2P: ['P2P1', 'P2P2'],
    SLOT: ['SLOT'],
    KENO: ['KENO'],
    genImg: productGroup => typeof productGroup==='string' && (objOneWalletCardIcon[productGroup]||[]).map(_=><img style={{marginLeft:5}} src={'/cn/img/1wallet/cardico/'+_+'.png'} alt={_} />)
}

export const enumPromoType = {
    MANUAL_PRE_BONUS:'Manual Pre-Bonus',
    MANUAL_ITEM:'Manual Item',
    RELOAD_BONUS:'Bonus',
    MANUAL2024:'Manual',
};

export const enumVisibility = {OFF:'off', PUBLIC:'public', MEMBER:'member'};

export const enumPromoActionType = {
    NO_ACTION: 'NO_ACTION',
    FUND_IN: 'FUND_IN',
    APPLY_FORM: 'APPLY_FORM',
    SOS: 'SOS',
    LIVECHAT: 'LIVECHAT',
    DEPOSIT_PAGE_ONLY: 'DEPOSIT_PAGE_ONLY'

    // 2025 start
    , NO_FORM: 'NO_FORM',
};

/*
1/ Strapi UI actionType: 'APPLY_FORM', 'NO_FORM' 時，只能選BL Campaign List applicationType: "ENROL_FORM" (applicationTypeId: 3)
2/ Strapi UI 原visibility: 'OFF' 時，只能選BL Campaign List applicationType: "MANUAL_UPLOAD" (applicationTypeId: 4)
*/

/**
 * So far, `Item` means: manual pre-bonus, bonus and manual item.
 */
export const Promo2025ItemModule = (function(){
    const {
        getCampaignId = item => item.campaignId,
        getApplicationType = item => item.campaignApplicationType || item.applicationType,
        getBonusGroupId = item => item.bonusData?.groupID || item.bonusData?.bonusRuleGroupId,
        getBonusId = item => item.bonusId || item.bonus_id,
        isReloadBonus = item => {
            let PROMO_TYPE = item?.promoType || item?.promotionType || '';
            return PROMO_TYPE===enumPromoType.RELOAD_BONUS;
        },
        _isManual = (item, manualType='') => {
            let PROMO_TYPE = item?.promoType || item?.promotionType || '';
            if (manualType==='2024') {
                return PROMO_TYPE===enumPromoType.MANUAL2024;
            } else if (manualType==='ALL') {
                return [enumPromoType.MANUAL_PRE_BONUS, enumPromoType.MANUAL_ITEM, enumPromoType.MANUAL2024].includes(PROMO_TYPE);
            } else if (manualType==='PRE_BONUS') {
                return PROMO_TYPE===enumPromoType.MANUAL_PRE_BONUS;
            } else if (manualType==='PREBONUS_ITEM') {
                return PROMO_TYPE===enumPromoType.MANUAL_ITEM;
            }
            return undefined;
        },
        isManual = item => _isManual(item, 'ALL'),
        isManualPreBonus = item => _isManual(item, 'PRE_BONUS'),
        isManualItem = item => _isManual(item, 'PREBONUS_ITEM'),
        isManual2024 = item => _isManual(item, '2024'),
        isManualPreBonusWithForm = item => Promo2025ItemModule.isManualPreBonus(item) && item.actionType === enumPromoActionType.APPLY_FORM,
        isManualPreBonusWoForm = item => Promo2025ItemModule.isManualPreBonus(item) && item.actionType === enumPromoActionType.NO_FORM,
        isManualItemWithForm = item => Promo2025ItemModule.isManualItem(item) && item.actionType === enumPromoActionType.APPLY_FORM,
        isManualItemWoForm = item => Promo2025ItemModule.isManualItem(item) && item.actionType === enumPromoActionType.NO_FORM,

        isNeedToBeApplied = (item, opt={}) => {
            const is_manual = isManualPreBonus(item) || isManualItem(item);
            const is_reload_bonus = isReloadBonus(item);
            const IS_GUEST = isGuest();
            const IS_LOGIN = !IS_GUEST;
            const IS_NO_ACTION = item.actionType === enumPromoActionType.NO_ACTION;
            const NOT_NO_ACTION = item.actionType !== enumPromoActionType.NO_ACTION;
            const IS_FUNDIN = item.actionType===enumPromoActionType.FUND_IN;
            if (IS_LOGIN && is_manual && NOT_NO_ACTION && !opt.campaignList) {
                console.error('missed opt.campaignList');
                return;
            }
            if (IS_LOGIN && is_reload_bonus && IS_FUNDIN && !opt.appliedHistories) {
                console.error('missed opt.appliedHistories');
                return;
            }
            let ret; // undefined
            if (IS_GUEST) {
                if (is_manual) {
                    ret = NOT_NO_ACTION;
                } else if (is_reload_bonus) {
                    if (IS_NO_ACTION) {
                        ret = false;
                    } else if (IS_FUNDIN) {
                        ret = true;
                    }
                }
            } else { // loginned
                if (is_manual) {
                    ret = NOT_NO_ACTION; // if true, use campaign_id and campaignList to check matching or not
                } else if (is_reload_bonus) {
                    if (IS_NO_ACTION) {
                        ret = false;
                    } else if (IS_FUNDIN) {
                        ret = true; // use AppliedHistory api "status" > show final status button.
                    }
                }
            }
            return ret || item.isNeedToBeApplied;
        }, // true 2.立即申请orOther   false 2.查看详情

        manualPreBonus = {
                isEligible: item => item.isEligible, // 3. 符合资格  CTAinTNC:开始优惠
                isVerifying: item => item.isVerifying,
                isApplied: item => item.isApplied,
                isClaimed: item => item.isClaimed,
            },
        reloadBonus = {
                isEligible: item => item.isEligible,
                isApplied: item => item.isApplied,
                isFull: item => item.isFull,
            },
    } = {};

    return {
        getBonusGroupId, getBonusId, getCampaignId, getApplicationType,
        isReloadBonus, isManual, isManualPreBonus, isManualItem, isManual2024,
        isManualPreBonusWithForm, isManualPreBonusWoForm,
        isManualItemWithForm, isManualItemWoForm,
        isNeedToBeApplied, manualPreBonus, reloadBonus,
        // isNoNeedToBeApplied,
    };
})();

/**
 *
 * -----------------------------------------------------------------
 * The following is promotion functions made from 2023~2024.12.31
 * -----------------------------------------------------------------
 *
 */
// 取得免費優惠資料
export async function getFreePromotions() {
    let params = {
        type: 'free'
        // transactionType: '',
        // wallet: ''
    };
    return PromotionList(params) // 返回一個 Promise
        .then((res)=>{
            console.log('PromotionFreeList =======================>',res)
            if (res){
                const freebetWithBonus = Array.isArray(res) ? res.filter(v => v.bonusData) : [];
                return freebetWithBonus
            }
        });
}

// 取得已申請優惠資料
export async function getAppliedHistory() {
    let [startDate, endDate] = getStartEndDateByNow();
    let params = {
        startDate,
        endDate
    };
    return GetAppliedHistory(params).then((res) => {
        console.log('GetAppliedHistory res ========>', res)
        if (res){
            return res
        }
    })
}

// 取得Promotions資料 （加入本地存储 优化加载速度体验）
export async function getPromotionList() {
    let params = {
        type: 'general',
        transactionType: '',
        wallet: ''
    };
    return PromotionList(params) // 返回一個 Promise
        .then((res)=>{
            console.log('PromotionList resssss ======================= >',res)
            if (res){
                return res
            }
        });
}

/**
 * @description: 获取优惠的菜单分类 （加入本地存储 优化加载速度体验）
 * @return {*}
 */
export async function getPromotionCategories() {
    const isSupportWebp = global.isSupportWebp ? "?displaying_webp" : "";

    let Categories =
        JSON.parse(localStorage.getItem("PromotionCategories")) || [];

    const res = await get(ApiPort.PromotionCategories + isSupportWebp);

    if (res && res.length != 0) {
        Categories = res || [];

        localStorage.setItem("PromotionCategories", JSON.stringify(Categories));
    }

    return Categories;
}

//取得反水資料
export async function getRebateData(
    startTime = moment(new Date()).format("YYYY-MM-DD"),
    endTime = moment(new Date()).format("YYYY-MM-DD"),
) {
    let params = {
        startDate: startTime,
        endDate: endTime
    };
    console.log('GetRebateList params !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ', params)
    return GetRebateList(params).then((res)=>{
        console.log('GetRebateList res !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! ', res)
        if(res){
            return res.result
        }
    })
}
