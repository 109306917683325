import HostConfig from "$ACTIONS/Host.config";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { post } from "$ACTIONS/TlcRequest";

const fn = u => u.split('/').map((v,i)=>i<3 ? v+'/' : '').join('');

const rewardUrlToAutoLoginUrl = (EncryptedAccessToken='{EncryptedAccessToken}') => {
    return HostConfig.Config.SkyKingClubRewardsUrl + '/Rewards/login.aspx?Currency=CNY&EncryptedAccessToken='+EncryptedAccessToken;
}

export const rewardUrlToAutoLogoutUrl = () => {
    return HostConfig.Config.SkyKingClubRewardsUrl + '/Rewards/login.aspx?Action=Logout';
}

export const rewardHomeUrl = () => {
    return HostConfig.Config.SkyKingClubRewardsUrl + '/Rewards/MyVIP.aspx?Pop=1';
}

export const doFetchPOSTRewardTokenEncry = (
    accessToken, refreshToken) => {
    accessToken = accessToken.replace(/bearer /i, '').replace(/"/g, '').replace(/'/g, '');
    refreshToken = refreshToken.replace(/"/g, '').replace(/'/g, '');
    let payload = {currency:'CNY', accessToken, refreshToken};
    return post(ApiPort.fetchPOSTRewardTokenEncry, payload).then(res=>{
        let rewardUrl = rewardUrlToAutoLoginUrl(res?.result?.encryptedAccessToken);
        return new Promise(resolve=>{
            resolve(Object.assign(res||{}, {_rewardUrl:rewardUrl}))
        });
    });
};

export const doFetchPOSTRewardTokenDecry = (encryptedAccessToken, encryptedRefreshToken) => {
    let payload = {currency:'CNY', encryptedAccessToken, encryptedRefreshToken};
    return post(ApiPort.fetchPOSTRewardTokenDecry, payload).then(res=>{
        return new Promise(resolve=>{
            res = res||{};
            let result = res.result||{};
            resolve(Object.assign(res, {_plainAccessToken:result.accessToken, _plainRefreshToken:result.refreshToken}))
        });
    });
};

export const genAutoIframe = (HTML_ID, src) => {
    const bd = document.getElementsByTagName('body');
    if (!bd || !bd.length) { return; }

    const ifrById = document.getElementById(HTML_ID);
    if (ifrById) { ifrById.remove(); }

    // re-create
    const ifr = document.createElement('iframe');
    ifr.src = src;
    ifr.witdh = ifr.height = 50;
    ifr.id = HTML_ID;
    bd = bd[0];
    bd.appendChild(ifr);
    setTimeout(()=>{
        let i = document.getElementById(HTML_ID);
        if (i) { i.style='position:fixed;top:-999px'; }
    },2500)
}

