import ModalLockedBalance from "@/UI/ModalLockedBalance"

const LockedBalanceTrait = that => {
    return {
        init: () => {
            that.state.isOpenModalLockedBalance = false;
        },
        show: () => {
            that.setState({isOpenModalLockedBalance: true})
        },
        hide: () => {
            that.setState({isOpenModalLockedBalance: false})
        },
        renderModalLockedBalance: (storeBalanceV2Result, storeBonusProgressResult) => {
            return <ModalLockedBalance
                storeBalanceV2Result={storeBalanceV2Result}
                storeBonusProgressResult={storeBonusProgressResult}
                isOpen={that.state.isOpenModalLockedBalance}
                onClose={()=>{
                    that.setState({isOpenModalLockedBalance:false})
                }}></ModalLockedBalance>
        }
    }
};
export default LockedBalanceTrait;