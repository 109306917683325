/*
 * @Author: Alan
 * @Date: 2023-01-09 16:20:08
 * @LastEditors: Alan
 * @LastEditTime: 2023-05-23 13:57:52
 * @Description: 头部注释
 * @FilePath: \F1-M1-WEB-Code\config\Globalfun.js
 */
/* 全局函数初始化 */
if (process.browser) {
    // webp支持否判斷初始化
    const supportWebp = localStorage.getItem("isSupportWebp");
    global.isSupportWebp = false;

    if (typeof supportWebp === "boolean") {
    } else {
        // try {
        // 	global.isSupportWebp = document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0;
        // 	localStorage.setItem("isSupportWebp", global.isSupportWebp)
        // } catch (err) {
        // 	global.isSupportWebp = false
        // 	localStorage.setItem("isSupportWebp", global.isSupportWebp)
        // }
    }
    // function gtag() {
    // 	dataLayer.push(arguments);
    // }
    // gtag('js', new Date());
    // gtag('config', 'UA-45814256-7');
    let piwikTimer = null;
    let piwikUrl = [];


    function _avoidExecutePiwikOver1TimeInShortTime(nameFn, fn) {
        return function(...args) {
            const {
                avoid = _avoidExecutePiwikOver1TimeInShortTime,
                pool = avoid.poolCoolDown,
                check = nameFn + ':' + JSON.stringify(args),
            } = {};

            if (pool.includes(check)) { return; }

            fn.apply(this, args); // execute immediatly

            pool.push(check); // enable cool-down

            setTimeout((function(check) {
                return () => {
                    avoid.poolCoolDown = avoid.poolCoolDown.filter(v=>v!==check) // disable cool-down
                };
            })(check), 333);
        };
    }
    _avoidExecutePiwikOver1TimeInShortTime.poolCoolDown = [];

    function pushUrl(_paq, url, trackingTitle=null) {
            // 這塊貌似可以不要? 當傳入的url與現行網站的url相同是會被擋住，其目的為何?
            // if (!window.piwikLoadFinished && ~window.location.href.indexOf(url)) {
            //         return;
            //     }
            _paq.push(["setDocumentTitle", trackingTitle||null]); // reset DocumentTitle in Piwik via `null`
            _paq.push(["setCustomUrl",  url]);
            _paq.push(["trackPageView"]);
    }

    ~(me => global.Pushgtagpiwikurl = _avoidExecutePiwikOver1TimeInShortTime('Pushgtagpiwikurl', me))(
    global.Pushgtagpiwikurl = (url, trackingTitle='') => {
        if (!url) {
            return;
        } else {
            url = window.location.origin + "/cn/" + url + "/";
        }
        if (typeof _paq === "object") {
            pushUrl(_paq, url, trackingTitle);
        } else {
            piwikUrl.push([url, trackingTitle]);
            clearInterval(piwikTimer);
            piwikTimer = setInterval(() => {
                if (typeof _paq === "object") {
                    clearInterval(piwikTimer);
                    Array.isArray(piwikUrl) &&
                        piwikUrl.length &&
                        piwikUrl.forEach((v) => {
                            pushUrl(_paq, ...v);
                        });
                    piwikUrl = [];
                }
            }, 1000);
        }
    });
    global.PushgtagdataWithEventValue = (eventCategory, action, name, evtValue, aryCustomVariable) => {
        global.Pushgtagdata(eventCategory, action, name, false, false, {evtValue, aryCustomVariable});
    }
    ~(me => global.Pushgtagdata = _avoidExecutePiwikOver1TimeInShortTime('Pushgtagdata', me))(
    global.Pushgtagdata = (eventCategory, action, name, customVariableKey = false, customVariableValue = false, conf={}, customUrl) => {
        const evtValue = conf?.evtValue;
        if (conf?.aryCustomVariable && !Array.isArray(conf?.aryCustomVariable)) {
            if (location.href.includes('localhost:')) {
                alert('please make sure aryCustomVariable is Array.' + [eventCategory, action, name].join("\n"));
            }
            let keys = Object.keys(conf?.aryCustomVariable);
            if (keys.length === 2 && keys.includes('customVariableKey') && keys.includes('customVariableValue')) { // patch conf.aryCustomVariable
                conf = conf || {};
                conf.aryCustomVariable = [conf.aryCustomVariable];
            }
        }
        let customVariableArr = conf?.aryCustomVariable || [];
        if (customVariableKey) {
            customVariableArr.push({customVariableKey, customVariableValue})
        }
        /*PiWA 追中*/
        console.log("PiWA 追中:", eventCategory, action, name);
        if (eventCategory == undefined) {
            return;
        }
        let data = eventCategory.replace(/[&\|\\\*^%$#@\-]/g, "");
        console.log(data, "追中------benji");
        if (typeof _paq === "object") {
            if (typeof customUrl === "string") {
                _paq.push(["setCustomUrl", customUrl]);
            }
            // _paq.push(["trackEvent", data, "touch"]);
            customVariableArr.length && customVariableArr.forEach((item,i)=> { item.customVariableKey && _paq.push([ 'setCustomVariable', i+1, item.customVariableKey, item.customVariableValue , "page"])})
            let aryTrackEvent = ["trackEvent", data, action ? action : "touch", name];
            if (evtValue) {
                aryTrackEvent.push(evtValue);
            }
            _paq.push(aryTrackEvent);
            customVariableArr.length && customVariableArr.forEach((item,i)=> _paq.push([ 'deleteCustomVariable', i+1, "page"]));
        }
    });
    let StagingApi = Boolean(
        [
            "p5stag.fun88.biz",
            "p5stag1.fun88.biz",
            "p5stag2.fun88.biz",
            "p5stag3.fun88.biz",
            "p5stag4.fun88.biz",
            "p5stag5.fun88.biz",
            "p5stag6.fun88.biz",
            "p5stag7.fun88.biz",
            "p5stag8.fun88.biz",
            "p5stag9.fun88.biz",
            "p5stag10.fun88.biz",
            "p5stag11.fun88.biz",
            "p5stag12.fun88.biz",
            "p5stag13.fun88.biz",
            "p5stag14.fun88.biz",
            "p5stag15.fun88.biz",
            "p5stag16.fun88.biz",
            "localhost:8383",
            "127.0.0.1:8080",
        ].find((v) => global.location.href.includes(v))
    );
    setTimeout(() => {
        let e2src = "https://e2.platform88798.com/E2/EagleEye.js";
        if (StagingApi) {
            e2src = "https://ytl.ylyofb45n.com/E2/EagleEye.js";
        }
        // let script1 = document.createElement('script');
        // script1.src = 'https://mpsnare.iesnare.com/snare.js';
        // script1.type = 'text/javascript';
        // document.querySelectorAll('body')[0].appendChild(script1);
        let script2 = document.createElement("script");
        script2.src = e2src;
        script2.type = "text/javascript";
        document.querySelectorAll("body")[0].appendChild(script2);
    }, 5000);

    if (StagingApi) {
        /*PiWA 追中*/
        !function(s,e,i,t){s[i]=s[i]||[],s[i].push({start:(new Date).getTime(),event:"stg.start"});var a,n,c,r,o=e.getElementsByTagName("script")[0],g=e.createElement("script"),l=(s.location.href.match("stg_debug")||e.cookie.match("stg_debug"))&&!s.location.href.match("stg_disable_debug");a="stg_debug",n=l?1:"",m="",(c=l?14:-1)&&((r=new Date).setTime(r.getTime()+24*c*60*60*1e3),m="; expires="+r.toUTCString()),e.cookie=a+"="+n+m+"; path=/";var m=[];l&&m.push("stg_debug"),m=0<m.length?"?"+m.join("&"):"",g.async=!0,g.src="//analytics.ravelz.com/containers/"+t+".js"+m,o.parentNode.insertBefore(g,o),function(e,a,t){e[a]=e[a]||{};for(var n=0;n<t.length;n++)!function(t){e[a][t]=e[a][t]||{},e[a][t].api=e[a][t].api||function(){var e=[].slice.call(arguments,0);"string"==typeof e[0]&&s[i].push({event:a+"."+t+":"+e[0],parameters:[].slice.call(arguments,1)})}}(t[n])}(s,"ppms",["tm","cm"])}(window,document,"dataLayer"
        ,"662ec2b9-18e7-4bdf-a58a-3184fa3205be");
        /*PiWA 追中*/

        /**
         * ST  aacd115c-d97b-4c88-b763-b62aa1d1c56d
         * Prod  2948cb02-cafc-40f0-adad-cc74eec891a8
         */
    } else {
        /*PiWA 追中*/
        !function(s,e,i,t){s[i]=s[i]||[],s[i].push({start:(new Date).getTime(),event:"stg.start"});var a,n,c,r,o=e.getElementsByTagName("script")[0],g=e.createElement("script"),l=(s.location.href.match("stg_debug")||e.cookie.match("stg_debug"))&&!s.location.href.match("stg_disable_debug");a="stg_debug",n=l?1:"",m="",(c=l?14:-1)&&((r=new Date).setTime(r.getTime()+24*c*60*60*1e3),m="; expires="+r.toUTCString()),e.cookie=a+"="+n+m+"; path=/";var m=[];l&&m.push("stg_debug"),m=0<m.length?"?"+m.join("&"):"",g.async=!0,g.src="//analytics.ravelz.com/containers/"+t+".js"+m,o.parentNode.insertBefore(g,o),function(e,a,t){e[a]=e[a]||{};for(var n=0;n<t.length;n++)!function(t){e[a][t]=e[a][t]||{},e[a][t].api=e[a][t].api||function(){var e=[].slice.call(arguments,0);"string"==typeof e[0]&&s[i].push({event:a+"."+t+":"+e[0],parameters:[].slice.call(arguments,1)})}}(t[n])}(s,"ppms",["tm","cm"])}(window,document,"dataLayer"
        ,"86cde5a5-9489-4270-836d-a073944a3de3");
        /*PiWA 追中*/
    }
}
