import { message, Modal } from "antd";
import { INVOICE_METHODCODE_OF_CTC, DEPOSIT_APPLY_ERRMSG_FOR_VAL18035withNoKYC, ARY_NEED_PII_KYC_CHECK } from "$ACTIONS/constantsData";
import { get, post, patch } from "$ACTIONS/TlcRequest";
import { convertToV1Response } from "$ACTIONS/balanceRequest";
import { ApiPort, APISET, APISETS } from "$ACTIONS/TLCAPI";
import { decorateBffscBonusV2Api4ForwardCompatible } from '$ACTIONS/helper';
import { getSeonSession } from "$ACTIONS/helper";

/**
 * 获取余额
 * @param {Function} call 回调函数
 * @param {Function} setLoadingStatus 设置Loading状态的函数
 * @returns {Promise} 返回Promise对象
 */
export async function GetAllBalance(call, setLoadingStatus, conf) {
    try {
        setLoadingStatus && setLoadingStatus(true);
        let res = await get(ApiPort.GETBalance, conf && conf.reqMoreConf);
        if (res.isSuccess) {
            res = convertToV1Response(res);
        }
        console.log(res, "钱包数据");
        typeof call === "function" && call(res);
        return res;
    } catch (error) {
        console.log("GetAllBalance error:", error);
        return null;
    } finally {
        setLoadingStatus && setLoadingStatus(false);
    }
}

// 获取提款验证相关数据
export function GetWithdrawalVerification(call, setLoadingStatus) {
    setLoadingStatus && setLoadingStatus(true);
    get(ApiPort.WithdrawalVerification + APISET)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetWithdrawalVerification error:", error);
            return null;
        })
        .finally(() => {
            setLoadingStatus && setLoadingStatus(false);
        });
}

// 获取支付方式
export function GetPayList(call, catchCall) {
    get(ApiPort.GETPaymentlistAPI)
        .then((res) => {
            if (res && res.IS_FEAT_MAINTENANCE) {
                return;
            }
            call(res);
        })
        .catch((error) => {
            console.log("GetPayList error:", error);
            catchCall && catchCall();
            return null;
        });
}

export const DEFAULT_PAY_METHOD = ''; // first for EBpay/HIpay/C988... selector

/**
 * 获取支付渠道默认值
 * @param {object} payMethodsDetail 当前支付方式详情
 */
export function GetAvailableMethods(payMethodsDetail) {
    if (
        payMethodsDetail &&
        Array.isArray(payMethodsDetail.availableMethods) &&
        payMethodsDetail.availableMethods.length
    ) {
        if (payMethodsDetail.code === 'QQ') { // PlanB
            if (payMethodsDetail.availableMethods.length === 1) {
                return payMethodsDetail.availableMethods[0].methodCode
            }
            return DEFAULT_PAY_METHOD;
        }
        if (
            payMethodsDetail.availableMethods.length >= 2 &&
            payMethodsDetail.code !== "CTC"
        ) {
            if (
                payMethodsDetail.availableMethods[0] == "DEFAULT" ||
                payMethodsDetail.availableMethods[0].methodCode == "DEFAULT"
            ) {
                return payMethodsDetail.availableMethods[1].methodCode;
            } else {
                return payMethodsDetail.availableMethods[0].methodCode;
            }
        } else {
            return payMethodsDetail.availableMethods[0].methodCode;
        }
    }
}

export function GetPayCoinRateDetail(type, call, payListOrValue, conf={}) {
    if (type!=='CTC' || payListOrValue!==INVOICE_METHODCODE_OF_CTC) {
        return;
    }
    post(ApiPort.POSTBffscDepositDetailsAPI2, {
            "dynamicObject": {
                "coinsCurrency": conf && conf.coinsCurrency,
                "fiatCurrency": "CNY",
                "exchangeAmount": 1,
                "methodCode": payListOrValue
            }
        }, undefined, {IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx: true})
        .then(res => {
            if (res && res.IS_FEAT_MAINTENANCE) {
                return;
            }
            call(res);
        })
        .catch((error) => {
            console.log("GetPayCoinRateDetail error:", error);
            return null;
        });
}

// 获取支付方式的详情
export function GetPayDetail(type, call, payListOrValue) {
    let MethodCode = "";
    if (Array.isArray(payListOrValue)) {
        const payMethodsDetail = payListOrValue.find(
            (item) => item.code === type
        );
        MethodCode = GetAvailableMethods(payMethodsDetail) || "";
    } else if (typeof payListOrValue === "string") {
        MethodCode = payListOrValue;
    }

    const IS_CTC_INVOICE_PAY_METHOD = (type == 'CTC' && payListOrValue===INVOICE_METHODCODE_OF_CTC);
    const IS_PII_KYC_USAGE = ARY_NEED_PII_KYC_CHECK.includes(type);
    const url =
        type == "ODP" || IS_CTC_INVOICE_PAY_METHOD || IS_PII_KYC_USAGE
            ? ApiPort.GETBffscDepositDetailsAPI
            : ApiPort.GETDepositDetailsAPI;

    const apa =
        url +
        type +
        "&MethodCode=" +
        MethodCode +
        "&isMobile=" +
        (type === "BCM" ? "true" : "false") +
        "&hostName=" +
        ApiPort.LOCAL_HOST;

    get(
        url +
            type +
            "&MethodCode=" +
            MethodCode +
            "&isMobile=" +
            (type === "BCM" ? "true" : "false") +
            "&hostName=" +
            ApiPort.LOCAL_HOST
    )
        .then((res) => {
            if (res && res.IS_FEAT_MAINTENANCE) {
                return;
            }
            call(res);
            console.log("bctype=====", type);
            console.log("apa===", apa);
        })
        .catch((error) => {
            console.log("GetPayDetail error:", error);
            return null;
        });
}

// 获取目标账户列表
export function GetWalletList(call) {
    const localWalletList = localStorage.getItem("walletList");
    localWalletList === null || localWalletList === ""
        ? get(ApiPort.GETWallets)
              .then((res) => {
                  if (res) {
                      localStorage.setItem("walletList", JSON.stringify(res));
                      call(res);
                  }
              })
              .catch((error) => {
                  console.log("GetWalletList error:", error);
                  return null;
              })
        : call(JSON.parse(localWalletList));
}

// 获取可申请优惠列表
export function GetWalletBonus(AccountType, call, transactionType) {
    get(
        ApiPort.GETBonuslistAPI +
            transactionType +
            "&" +
            "wallet=" +
            AccountType +
            '' // APISETS
    )
        .then((res) => {
            res = decorateBffscBonusV2Api4ForwardCompatible(res);
            call(res);
        })
        .catch((error) => {
            console.log("GetWalletBonus error:", error);
            return null;
        });
}

// 提交充值 || 提交提款
// For those error which would execute `message.error(displayErrorMsg);`, you can consider set conf.IS_ALREADY_HANDLE_SUCCESS_FALSE_UI_UX as true like ODP and EBpay_DP.
export function CommonPostPay(data, call, conf={}, reqConf={}) {
    const promise = getSeonSession();
    promise.then(seonSess => {
            data.seonSession = seonSess;
            console.log("data", data);
            return post(ApiPort.POSTApplications, data, '', reqConf)
        })
        .then((res) => {
            const IS_HTTP4xx5xx = res && res.Http && res.Http>=400 && res.Http<=599
            if (IS_HTTP4xx5xx) {
                res = res?.Json
            }
            console.log("res==commonpostpaydata", data);
            console.log("res==commonpostpay", res);

            /**
             * 1. 2024/10, first used for OA and ODP
             * 2. consider ODP, we need to put this logic earlier than `call(res);` because ODP will trigger P115001
             */
            if (conf && conf.caller && conf.caller.DepositNameKYCTrait?.handledVAL18031toVAL18035UXofPOSTPaymentApplication(res, conf.caller)) {
                return;
            }

            call(res);
            if (conf && conf.IS_ALREADY_HANDLE_SUCCESS_FALSE_UI_UX) { // ticket2144
                return;
            }
            if (!res.isSuccess) {
                // call(res);
                //return data.transactionType !== "Withdrawal" && message.error(res.errorMessage || "数据异常，请联系在线客服！");
                /*let displayErrorMsg = res.errorMessage
      console.log("res",res)
      if (displayErrorMsg.indexOf("小同客服")>-1 && res.isPopup) {
        let errorMessageArr = displayErrorMsg.split("小同客服")
        displayErrorMsg = errorMessageArr.map((item, index) => {
          if (index != errorMessageArr.length-1) {
            return <><span className="content-text">{item}</span><a onClick={()=>{global.PopUpLiveChat()}} ><span style={{ fontWeight : "bold" }}>小同客服</span></a></>
          } else {
            return <span>{item}</span>
          }

        });
      }*/
                let displayErrorMsg = res.errorMessage;
                if (res.isPopup) {
                    displayErrorMsg = (
                        <span>
                            抱歉，由于您还有未完成的充值记录，暂时无法重复提交。若您已转账成功，请
                            <a
                                onClick={() => {
                                    global.PopUpLiveChat();
                                }}
                            >
                                <span style={{ fontWeight: "bold" }}>
                                    联系小同客服
                                </span>
                            </a>
                            。
                        </span>
                    );
                }

                let firstErr = res && res.errors && res.errors.length && res.errors[0];
                let errCode = firstErr?.errorCode;
                const IS_ERR_VAL18035 = errCode==='VAL18035';
                const IS_ERR_VAL18035_AND_YesKYC = IS_ERR_VAL18035; // TODO: integrate KYC value when pharse2
                const IS_ERR_VAL18035_AND_NoKYC = IS_ERR_VAL18035; // TODO: integrate KYC value when pharse2
                if (IS_ERR_VAL18035_AND_NoKYC) { // follow backend-doc table
                    message.error(DEPOSIT_APPLY_ERRMSG_FOR_VAL18035withNoKYC)
                    return;
                }

                if (res.errors) {
                    displayErrorMsg =
                        res.errors[0].description ||
                        res.errors[0].message ||
                        " 抱歉，系统忙碌中，建议您使用其他存款方式";
                }

                if (
                    data.transactionType !== "Withdrawal" &&
                    data.paymentMethod !== "SR" &&
                    data.paymentMethod !== "PPB" &&
                    res.errorCode !== "P101007"
                ) {
                    if (
                        (data.methodcode === "AliBnBQR" ||
                            data.methodcode === "WCBnBQR") &&
                        res.errorCode === "P101105"
                    ) {
                        Modal.confirm({
                            title: "温馨提示",
                            icon: null,
                            centered: true,
                            okText: "返回充值首页",
                            cancelText: "在线客服",
                            cancelButtonProps: {
                                type: "primary",
                                ghost: true,
                                onClick: () => {
                                    paybnbConfirm.destroy();
                                    global.PopUpLiveChat();
                                },
                            },
                            content: (
                                <div style={{ textAlign: "left" }}>
                                    {displayErrorMsg}
                                </div>
                            ),
                            onOk: () => {},
                            onCancel: () => {},
                        });
                    } else {
                        message.error(displayErrorMsg);
                        // Modal.info({

                        // 	className: 'confirm-modal-of-public',
                        // 	icon: <div />,
                        // 	okText: '关闭',
                        // 	title: <div style={{ textAlign: 'center' }}>温馨提示</div>,
                        // 	content: <div style={{ textAlign: 'center' }}>{<p>{displayErrorMsg}</p>}</div>
                        // });
                    }
                }
            }
            // else {
            //     call(res);
            // }
        })
        .catch((error) => {
            console.log("CommonPostPay error:", error);
            return null;
        })
        .finally(()=>{
            if (conf && conf.caller) {
                if (typeof conf.caller.props?.setLoading==='function') {
                    conf.caller.props.setLoading(false);
                }
                if (typeof conf.caller.setProgressModalVisible==='function') { // KYC-439
                    conf.caller.setProgressModalVisible(false);
                }
            }
        });
}

// 提交充值确认
export function CommonPostConfirmPay(data, call, conf={}) {
    let u = ApiPort.POSTPaymentConfirmStep +
    "ConfirmStep" +
    APISET +
    `&transactionId=${data.transactionId}`;
    if (conf && conf.IS_BFFSC_HOST) {
        u = ApiPort.POSTPaymentConfirmStep2; // https://arcadie.atlassian.net/wiki/spaces/BOBF/pages/260014957/POST+api+Payment+Applications+ConfirmStep
    }
    post(
        u,
        data
    )
        .then((res) => {
            res && call(res);
            if (res.isSuccess) {
                message.success(
                    "订单成立！交易正在进行中，您的存款将在指定时间内到账，感谢您的耐心等待！",
                    4
                );
            } else {
                message.error("输入的旧账号错误！");
            }
        })
        .catch((error) => {
            console.log("CommonPostConfirmPay error:", error);
            return null;
        }).finally(()=>{
            conf && conf.finalFunc && conf.finalFunc();
        });
}

// 提交转账
export function TransferSubmit(data, call) {
    post(ApiPort.POSTTransfer, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("TransferSubmit error:", error);
            return null;
        });
}

// 获取用户已绑定银行卡
export function GetMemberBanks(call) { // will deprecated
    get(ApiPort.GETMemberBanksfirst)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetMemberBanks error:", error);
            return null;
        });
}
export function GetMemberBanks2(call) {
    get(ApiPort.GETMemberBanksfirst2)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetMemberBanks2 error:", error);
            return null;
        });
}

// 获取提款方式
export function GetWithdrawalMethods(call) {
    get(ApiPort.GETCanWithdrawalPay)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetWithdrawalMethods error:", error);
            return null;
        });
}
export function GetWithdrawalMethodsDetail(paymentMethod, methodCode, call) {
    const hostName = ApiPort.LOCAL_HOST;
    const o = {paymentMethod, methodCode, hostName};

    let u = ApiPort.GETWithdrawalDetailsAPI2;
    for (let k in o) { u = u.replace('{'+k+'}', o[k]); }

    return get(u).then(res => {
        call(res);
    }).catch(error => {
        console.log("GetWithdrawalMethodsDetail error:", error);
        return;
    });
}

//获取提现泰达币的汇率
export function GetWithdrawalExchangeRate(data, call) {
    get(
        ApiPort.GetExchangeRate +
            "?CurrencyFrom=" +
            data.CurrencyFrom +
            "&CurrencyTo=" +
            data.CurrencyTo +
            "&baseAmount=" +
            data.baseAmount +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GetWithdrawalExchangeRate error:", error);
            return null;
        });
}

// 获取某个方式的提款详情
export function GETWithdrawalDetails(data, type, call) {
    get(
        ApiPort.GETWithdrawalDetailsAPI +
            "&method=" +
            data +
            "&MethodCode=" +
            type +
            "&isMobile=false&hostName=" +
            ApiPort.LOCAL_HOST
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("GETWithdrawalDetails error:", error);
            return null;
        });
}

// 查询会员数位货币钱包
export function CheckDigitalCoinWallet(call) {
    return CheckExchangeRateWallet({}, call)
}
// 查询会员虚拟货币钱包or数位货币钱包
export function CheckExchangeRateWallet(data, call) {
    data ||= {}
        const IS_CRYPTO = ['USDT-TRC20', 'USDT-ERC20'].includes(data.CryptoCurrencyCode);
        let type = IS_CRYPTO
                ? 'CryptoCurrency'
                : 'DigitalCoin'; //  EB, GB, KD, HI, C_988, C_808
        get(ApiPort.CheckExchangeRateWallet2 + '&type=' + type).then(res => {
            if (res?.result) {
                res.result.forEach((o,i)=>{
                    for (let k of Object.keys(o)) { o[k.toLowerCase()] = o[k] }
                    res.result[i] = o;
                });
                if (IS_CRYPTO) {
                    res.result = res.result.filter(v=>v.cryptocurrency===data.CryptoCurrencyCode)
                }
                res.result = res.result.map(o => {
                    return { // backward-compatibility for existed react code
                        id: o.id,
                        memberCode: o.membercode,
                        walletName: o.walletname,
                        walletAddress: o.walletaddress,
                        cryptocurrency: o.cryptocurrency, //IS_CRYPTO ? o.cryptocurrency : o.digitalcointype,
                        isActive: o.isactive,
                        isDefault: o.isdefault,
                        createdBy: o.createdby,
                        createdAt: o.createdat,
                        updatedBy: o.updatedby,
                        updatedAt: o.updatedat,
                    };
                })
                call(res)
            }
        })
        .catch((error) => {
            console.log("CheckExchangeRateWallet error:", error, isNewVer);
            return null;
        });
        return;
    /*
    get(
        ApiPort.CheckExchangeRateWallet +
            "?cryptoCurrencyCode=" +
            data.CryptoCurrencyCode +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("CheckExchangeRateWallet error:", error);
            return null;
        });
        */
}

// 添加虚拟货币錢包
export function AddExchangeRateWallets(data, call) {
    post(ApiPort.AddExchangeRateWallet2, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("AddExchangeRateWallet2 error:", error);
            return null;
        });
}

//设置默认钱包
export function setTDBDefaultWallet(data, call) {
    patch(`${ApiPort.setTDBDefaultWallet}${APISET}&walletId=${data.WalletID}`)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("setTDBDefaultWallet error:", error);
            return null;
        });
}

//用户的提款户口达到了限制设定
export function getWithdrawalSetUp(data, call) {
    get(
        ApiPort.CheckWithdrawalThreshold +
            "?BankAccountNumber=" +
            data.BankAccountId +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}
//虚例币支付二取消交易我已成功充值提交
export function InvoiceAutDeposit(data, call) {
    post(
        ApiPort.InvoiceAutCryptoDeposit +
            "?transactionID=" +
            data.transactionID +
            "&transactionHash=" +
            data.transactionHash +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("InvoiceAutCryptoDeposit error:", error);
            return null;
        });
}

//虚例币支付二取消交易
export function CancelTheDealMethod(data, call) {
    post(
        ApiPort.InvoiceAutCancelTheDeal +
            "?transactionId=" +
            data.transactionId +
            APISETS
    )
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("虚例币支付二取消交易:", error);
            return null;
        });
}

// 上传文件
export function PostUploadAttachment(data, call) {
    post(ApiPort.UploadAttachment, data)
        .then((res) => {
            call(res);
        })
        .catch((error) => {
            console.log("PostUploadAttachment error:", error);
            return null;
        });
}
