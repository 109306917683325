import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Popover } from "antd";
import { formatAmount, momentFormat } from "$ACTIONS/util";
import { piwikLockedBalance } from "$ACTIONS/OneWallet202501PiwikUtil";
import { isToggleOn } from "$ACTIONS/balanceRequest";
import { getBonusTextInTable, ALL_WALLET_PRODUCT_GROUP_ID, isPrebonus } from "$ACTIONS/bonusRequest";
import Newbie from "@/UI/Newbie"
import Pagination from "@/UI/Pagination"

const DEFAULT_REBATE_BONUS_DISPLAY_TEXT = '系统金额调整';
const PAGE_SIZE = 5;

const ModalLockedBalance = ({ isOpen, onClose, storeBalanceV2Result, storeBonusProgressResult }) => {
    if (typeof document==='undefined') { return null; } // avoid SSR error

    if (!isOpen) return null;

    storeBalanceV2Result = storeBalanceV2Result || {};
    if (!isToggleOn(storeBalanceV2Result)) { return null; }

    const [curPage, setCurPage] = useState(1);

    storeBonusProgressResult = storeBonusProgressResult || {};

    const noContract = !Array.isArray(storeBonusProgressResult.contract) || !storeBonusProgressResult.contract.length;

    useEffect(()=>{
        piwikLockedBalance.runUrl();
    }, []);

    return ReactDOM.createPortal(
        <div className="modallockedbalance-overlay">
            <div className="modallockedbalance-content" style={noContract ? {height:460} : {}}>
                <button className="close-button" onClick={onClose}>
                    ×
                </button>
                <div className="modallockedbalance-title">未完成流水金额</div>
                <div className="modallockedbalance-scroll" style={noContract ? {height:'auto'} : {}}>
                <div className="modallockedbalance-summary">
                    <div className="total-amount">
                        <div>
                            <b style={{ fontSize: 16, color: '#222' }}>未完成流水总金额</b>
                            <span style={{ fontWeight:400, marginLeft:8, color: '#999', fontSize: 12 }}>{storeBonusProgressResult.totalContractBalance > 0
                                ? '完成流水要求即可解锁至可提款金额'
                                : '完成流水要求，以解锁符合条件的存款和优惠金额至可提款金额'}</span>
                        </div>
                        <h3 style={{margin:'0 0 20px'}}>¥ {formatAmount(storeBonusProgressResult.totalContractBalance)}</h3>
                    </div>
                    <div className="locked-amount">
                        <div>
                            存款锁定
                            <span style={{ fontWeight:400, marginLeft:8, color: '#999', fontSize: 12 }}>完成一倍流水即可解锁至可提款金额</span>
                        </div>
                        <h3><span>¥ {formatAmount(storeBonusProgressResult.totalDepositContractBalance)}</span></h3>
                        {storeBonusProgressResult.totalDepositContractBalance>0 && storeBonusProgressResult.lastDepositContractUpdatedDate ?
                        <div style={{ fontSize: 10 }}>最后更新时间：{momentFormat(storeBonusProgressResult.lastDepositContractUpdatedDate)}</div> : null}
                    </div>
                </div>
                <div className="bonus-amount">
                    <h3 style={{fontSize:16}}>优惠锁定 <span style={{fontSize:16,margin:'0 0 16px'}}>¥ {formatAmount(storeBonusProgressResult.totalContractBalance - storeBonusProgressResult.totalDepositContractBalance) || '0'}</span></h3>
                    <table>
                        <thead>
                            <tr>
                                <th>游戏类别</th>
                                <th>优惠名称</th>
                                <th>派发时间</th>
                                <th>彩金金额</th>
                                <th>未完成流水金额</th>
                                <th>进度</th>
                            </tr>
                        </thead>
                        <tbody>
                            {noContract
                            ? <tr><td colspan="6" style={{padding:20,textAlign:'center'}}>目前没有优惠记录</td></tr>
                            : (()=>{
                                const startIndex = (curPage - 1) * PAGE_SIZE;
                                return storeBonusProgressResult.contract.slice(startIndex, startIndex + PAGE_SIZE);
                            })().map(item => {
                                let title = getBonusTextInTable(item);
                                let applyDateTime = momentFormat(item.applyDateTime);
                                return (<tr>
                                <td>{item.walletProductGroupId == ALL_WALLET_PRODUCT_GROUP_ID
                                        ? '所有类别'
                                        : item.walletProductGroupName}</td>
                                <td>
                                    <Popover
                                        content={title}
                                        overlayStyle={{ maxWidth: "180px" }}
                                        trigger="hover"
                                        placement="bottom"
                                    >
                                        <div className="box-2lines-w-dot">
                                            <div className="box-2lines-w-dot--">
                                                {title}
                                            </div>
                                        </div>
                                    </Popover>
                                </td>
                                <td style={{position:'relative'}}>
                                    {applyDateTime.slice(0,10)}<br />
                                    {applyDateTime.slice(-8)}
                                    {isPrebonus(item) && <Popover
                                        content={'结束时间：' + momentFormat(item.expiredDateTime)}
                                        title=""
                                        trigger="hover"
                                        placement="bottom"
                                    >
                                        <i
                                            onMouseEnter={()=>piwikLockedBalance.run6()}
                                            style={{
                                                position: "absolute", top:32, right:10,
                                                transform:'scale(1.2)',
                                                background: 'url(/cn/img/1wallet/00/info.svg) no-repeat',
                                                display: 'inline-block',
                                                width: 16,
                                                height: 16,
                                                verticalAlign: 'sub'
                                            }}
                                        ></i>
                                    </Popover>}
                                </td>
                                <td>¥ {formatAmount(item.initialBalance)}</td>
                                <td>¥ {formatAmount(item.contractBalance)}</td>
                                <td>
                                    <div>
                                        流水进度<br />
                                        <i>(完成流水进度才可解锁至可提款金额)</i>
                                    </div>
                                    <div>
                                        <i>{item.turnoverProgress}%</i>
                                        <u><b>{item.currentTurnover}</b> / {item.requiredTurnover}</u>
                                    </div>
                                    <div className="progress-bar">
                                        <div style={{ width: item.turnoverProgress+'%' }} className="progress-fill"></div>
                                    </div>
                                </td>
                            </tr>)
                            })}
                        </tbody>
                    </table>
                    {noContract || storeBonusProgressResult.contract.length < PAGE_SIZE ? null : <>
                    <div style={{ height: 12 }}></div>
                    <Pagination
                        currentPage={curPage}
                        totalPages={Math.ceil(storeBonusProgressResult.contract.length / PAGE_SIZE)}
                        onPageChange={n => {
                            setCurPage(n);
                        }}
                    /></>}
                </div>
                </div>{/*modallockedbalance-scroll*/}
            </div>
            <Newbie imgsrc="/cn/img/newbie/lock-balance.png"
                imgstyle={{width:900,marginLeft:-450,top:30,left:'50%'}}
                btnstyle={{top:638}}
                />
        </div>,
        document.getElementsByTagName('body')[0]
    );
};

export default ModalLockedBalance;
