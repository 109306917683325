/**
*  copy from components/Wallet/Withdrawal.js then modify
*/

export function sendPiwikEventWithEventValue(category, action, name, evtValue, aryCustomVariable) {
    global.PushgtagdataWithEventValue && global.PushgtagdataWithEventValue(category, action, name, evtValue, aryCustomVariable);
}
export function sendPiwikEvent(category, action, name) {
    global.Pushgtagdata && global.Pushgtagdata(category, action, name);
}
export function sendPiwikUrl(url, title) {
    global.Pushgtagpiwikurl && global.Pushgtagpiwikurl(url, title);
}

export function sendPiwikEventAtUrl(url, title, category, action, name) {
    if (global.Pushgtagpiwikurl && global.Pushgtagdata) {
        global.Pushgtagpiwikurl(url, title);
        global.Pushgtagdata(category, action, name);
    }
}
export function sendPiwikEventWithEventValueAtUrl(url, title, category, action, name, evtValue, aryCustomVariable) {
    if (global.Pushgtagpiwikurl && global.PushgtagdataWithEventValue) {
        global.Pushgtagpiwikurl(url, title);
        global.PushgtagdataWithEventValue(category, action, name, evtValue, aryCustomVariable);
    }
}

