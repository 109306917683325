/*
 * @Author: Alan
 * @Date: 2023-02-12 20:37:42
 * @LastEditors: ‘alanfortest’ thisalantest@outlook.com
 * @LastEditTime: 2023-10-06 10:21:29
 * @Description: 启动游戏组件（所有关于启动游戏皆用此组件）
 * @FilePath: \F1-M1-WEB-Code\components\Games\openGame.js
 */
import React from "react";
import { message, Modal, Button } from "antd";
import Router from "next/router";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { get } from "$ACTIONS/TlcRequest";
import { GetAllBalance } from "$DATA/wallet";
import Tag from "@/Games/Tag";
import { showSpin, hideSpin } from "../../store/spinSlice";
import { connect } from "react-redux";
import Image from "next/image";
import ImageWithFallback from "@/ImageWithFallback/";
import Announcement from "@/Announcement/";
import classNames from "classnames";
import { InstantGamesGameCatCode, isWindowOpenGame, isCookieNoOpenGame } from "$ACTIONS/constantsData";
import { getQueryVariable, isGuest, isThisAllowGuestOpenGame, isThisAllowGuestOpenGCategory } from "$ACTIONS/helper";
import { sendProductGamePiwikEvent4Home } from '$ACTIONS/ProductGamePiwikUtil';
import Mdl2025 from "@/UI/Mdl2025";
import { handleDepositClick } from "../CentralPayment/CentralPaymentModal/CentralPaymentUtils";

class openGame extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentList: [], // 当前显示的数据
        };
        if (props.OnRef) {
            props.OnRef(this);
        }
    }

    componentDidMount() {}

    /**
     * @description: 启动游戏
     * @param {*} items 游戏参数
     * @return {*}
     */
    openGame(items) {
        console.log("当前打开的游戏", items);
        let IS_GUEST = isGuest();
        let IS_LOGINNED_MEMBER = !IS_GUEST;
        let IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME = isThisAllowGuestOpenGame(items.gameCatCode, items.provider);
        let IS_ALLOW_GUEST_OPEN_GCATEGORY = isThisAllowGuestOpenGCategory(items.gameCatCode, items.provider);
        //如果未登录，弹出登录窗口 //點擊產品頁平台，跳转游戏大厅游戏列表页面不弹出登录窗口
        if (IS_GUEST && !items.isProductPage && !IS_ALLOW_GUEST_OPEN_IFRAME_OR_NEW_WINDOW_GAME && !IS_ALLOW_GUEST_OPEN_GCATEGORY) {
            global.goUserSign("1");
            return;
        }

        //检测账户是否封锁
        if (IS_LOGINNED_MEMBER && JSON.parse(localStorage.getItem("memberInfo"))?.isGameLock) {
            message.error("您的账户已被锁，请联系在线客服。");
            return;
        }

        //跳转游戏大厅游戏列表页面
        if (
            /*
            (items.provider == "VTG" && items.Type == "GameInfoPage") ||
            (items.provider == "VTG" && items.Type == "HeaderMenu") ||
            */
            items.OpenGamesListPage
        ) {
            Router.push({
                pathname: "/cn/Games/" + items.gameCatCode + "/lobby",
                query: {
                    vendor: items.gameCatCode,
                    name: items.provider,
                    type: "",
                    id: items.gameId || 0,
                },
            });
            return;
        }

        if (
            typeof SelfExclusionsisDisabled != "undefined" &&
            SelfExclusionsisDisabled
        ) {
            CheckSelfExclusions(true);
            return;
        }

        // if (
        //     !this.IsNoIframe(items.provider) &&
        //     !this.checkBalance(items.gameCatCode, items.provider)
        // ) {
        //     return;
        // }

        this.actionGame(items);
    }

    //不支持ifame
    IsNoIframe(provider) {
        let thirdPartyCookie = JSON.parse(
            localStorage.getItem("thirdPartyCookie")
        );
        if (
            (thirdPartyCookie == false &&
                isCookieNoOpenGame.find((v) => v == provider)) ||
            isWindowOpenGame.find((v) => v == provider)
        ) {
            return true;
        }
        return false;
    }


    _isEnterGameByJustRefreshGameUrl() {
        return typeof window!=='undefined' && window.location?.hash?.includes('REFRESH_AFTER_LOGIN_SUCC');
    }
    /**
     * @description: 游戏跳转
     * @return {*}
     */
    actionGame(items, isTriggerFromGameLobbyButton) {
        global.dataAboutCheckBalanceModalOpenedFromIframeOpenGame = { // integrating and workaround with existed code & the spec of this task/ticket
            theGameItem: items,
            isTriggerFromGameLobbyButton: isTriggerFromGameLobbyButton
        };

        const queryOfIsTriggerFromGameLobbyButton = `&trigger_from_game_lobby_button=${isTriggerFromGameLobbyButton ? 'YES' : 'NO'}`

        const isEnterGameByJustRefreshGameUrl = this._isEnterGameByJustRefreshGameUrl() // for example: guest enter game --> guest login successfully --> webpage will refresh current page to re-load game iframe.
        if (isEnterGameByJustRefreshGameUrl) { // supplying items by url.
            items = items || {};
            items.provider = Router.router.query?.name;
            items.gameCatCode = Router.router.query?.vendor;
            items.gameId = Router.router.query?.gameId || 0;
        }


        if (items.provider == "AVIATOR" || items.launchGameCode == "aviator") {
            //启动飞行游戏
            this.OpenSPR(queryOfIsTriggerFromGameLobbyButton);
            return;
        }
        let sportsPrams = "";
        if(items?.provider ==="IPSB" && items.event_Id && items.sport_Id){
            sportsPrams = `&event_Id=${items.event_Id}&sport_Id=${items.sport_Id}&`
        }
        console.log("🚀 ~ openGame ~ actionGame ~ items:", items)
        //直接iframe启动游戏
        Router.push(
            /* concat OpenGame path: see also components/Banner/img.js */
            `/cn/Games/OpenGame?vendor=${items.gameCatCode}&name=${
                items.provider
            }&id=${items.gameId}&type=&demo=${isGuest() ? 'true' : 'false'}&launchGameCode=${
                items.launchGameCode || ""
            }`+sportsPrams + queryOfIsTriggerFromGameLobbyButton
        );
    }

    /**
     * @description: 检查余额是否充足，进行相关执行
     * @return {*}
     */
    async checkBalanceNeedAsyncAPIChecking(code, provider) {
        const isEnterGameByJustRefreshGameUrl = this._isEnterGameByJustRefreshGameUrl();
        const isBalanceDoesNotSetup = (typeof window!=='undefined' && typeof window.TotalBal==='undefined');
        const NEED_SETUP_BALANCE_FROM_API_THEN_showBalanceError = isEnterGameByJustRefreshGameUrl
            && isBalanceDoesNotSetup;
        if (NEED_SETUP_BALANCE_FROM_API_THEN_showBalanceError) {
            const res = await GetAllBalance();
            res.result.map(function (item, index) {
                window[item.name] = item.balance;
            });
            return this.checkBalance(code, provider);
        }

        return this.checkBalance(code, provider)
    }
    checkBalance(code, provider) {
        this.setState({ providerTag: provider });
        /* note: In one-wallet, we do not need to check TotalBal,
        see also /l/message/19:meeting_MzRlOTVhYjEtMmNhZC00M2M2LWI5NWMtYWZhMTJlM2RhZWUx@thread.v2/1739157548566?context=%7B%22contextType%22%3A%22chat%22%7D

        if (window.TotalBal < 20) {
            this.setState({ showBalanceError: true, isDeposit: true });
            return false;
        }
        */
        const MIN_MONEY_REQUIRED = 10;
        global.MIN_MONEY_REQUIRED = MIN_MONEY_REQUIRED;

        if (!code) {
            let launchGameCode = getQueryVariable('launchGameCode');
            let launchGameCodeLower = typeof launchGameCode==='string' ? launchGameCode.toLowerCase() : '';
            const IS_ENTER_AVIATOR = getQueryVariable('name')=='SPR' && getQueryVariable('id')=='11746' && launchGameCodeLower=='aviator';
            if (IS_ENTER_AVIATOR) { // fix FOW-421: There is no getQueryVariable('vendor') when opening aviator clicking from HeaderMenuBar.js
                code = InstantGamesGameCatCode;
            }
        }

        switch (code) {
            case "Sportsbook":
            case "ESports":
                const status = window.SB < MIN_MONEY_REQUIRED;
                this.setState({ showBalanceError: status });
                return !status;
            case InstantGamesGameCatCode:
            case "P2P":
                const status2 = window.P2P < MIN_MONEY_REQUIRED;
                /*
                    (provider == "YBP" && window.YBP < MIN_MONEY_REQUIRED) ||
                    (provider != "YBP" && window.P2P < MIN_MONEY_REQUIRED);
                */
                this.setState({ showBalanceError: status2 });
                return !status2;
            case "LiveCasino":
                const status3 = window.LD < MIN_MONEY_REQUIRED;
                this.setState({ showBalanceError: status3 });
                return !status3;
            case "Slot":
                const IS_FISH_GAME_OF_SLOT = ['YBF', // <-- where to click?
                        'DBF', 'SWF', 'SPG',
                        // 'KYS' already under P2P
                        ].includes(provider);
                if (IS_FISH_GAME_OF_SLOT) {
                    const status4fish = window.P2P < MIN_MONEY_REQUIRED;
                    this.setState({ showBalanceError: status4fish });
                    return !status4fish
                }
                /*
                if (provider == "IMOPT") {
                    const status4pttiger = provider == "IMOPT" && window.IMOPT < MIN_MONEY_REQUIRED;
                    this.setState({ showBalanceError: status4pttiger });
                    return !status4pttiger;
                } else {*/
                const status4 = window.SLOT < MIN_MONEY_REQUIRED;
                    /*
                    (provider == "DBF" && window.DBF < MIN_MONEY_REQUIRED) ||
                    (provider != "DBF" && window.SLOT < MIN_MONEY_REQUIRED);
                    */
                this.setState({ showBalanceError: status4 });
                return !status4;
                /*}*/
            case "KenoLottery":
                const status5 = window.KENO < MIN_MONEY_REQUIRED;
                    /*
                    (provider == "YBK" && window.YBK < MIN_MONEY_REQUIRED) ||
                    (provider == "SGW" && window.KENO < MIN_MONEY_REQUIRED) ||
                    (provider == "LBS" && window.KENO < MIN_MONEY_REQUIRED) ||
                    (provider == "SLC" && window.SLC < MIN_MONEY_REQUIRED);
                    */
                this.setState({ showBalanceError: status5 });
                return !status5;
            default:
                return true;
        }
    }

    /**
     * @description: 开启飞行游戏
     * @return {*}
     */

    OpenSPR(queryOfIsTriggerFromGameLobbyButton) {
        const { showSpin, hideSpin } = this.props;
        showSpin([true, "启动游戏中..."]);
        get(
            ApiPort.CmsGames +
                `?gameType=InstantGames&gameSortingType=Default&provider=SPR&api-version=2.0&platform=web`
        ).then((res) => {
            hideSpin();
            if (res.result && res.result.gameDetails.length != 0) {
                let item = res.result.gameDetails.find(
                    (ele) => ele.launchGameCode === "aviator"
                );
                if (item) {
                    Router.push(
                        `/cn/Games/OpenGame?name=${item.provider}&id=${
                            item.gameId
                        }&type=&demo=${false}&launchGameCode=${
                            item.launchGameCode || ""
                        }` + queryOfIsTriggerFromGameLobbyButton
                    );
                } else {
                    Modal.info({
                        className: "confirm-modal-of-public",
                        icon: <div />,
                        okText: "知道了",
                        title: "温馨提醒",
                        content:
                            res.message ||
                            "您所打开的游戏正在维护中，请稍后再尝试进入。若您有任何疑问，请联系我们的在线客服。",
                    });
                }
            }
        });
    }

    render() {
        const { itemsData, customHtml, hideTag, QuickStartGame, className } =
            this.props;
        // console.log('当前打开的游戏--itemsData  '+JSON.stringify(itemsData), itemsData, global.dataAboutCheckBalanceModalOpenedFromIframeOpenGame)
        const getImageDimensions = (gameCatCode) => {
            switch (gameCatCode) {
                case "P2P":
                    return { width: 349, height: 216 };
                case "LiveCasino":
                    return { width: 358, height: 220 };
                case "Slot":
                    return { width: 294, height: 181 };
                default:
                    return { width: 400, height: 300 };
            }
        };

        return (
            <React.Fragment>
                {!QuickStartGame && (
                    <React.Fragment>
                        {/* 头部导航栏启动 */}
                        {itemsData &&
                            itemsData.Type &&
                            itemsData.Type == "HeaderMenu" && (
                                <a
                                    onClick={() => {
                                        if (itemsData.provider==='DGG') {
                                            Pushgtagdata('LiveDealer_TopNav', 'Go to DGG Lobby', 'LiveDealer_TopNav_C_DGG');
                                        }
/*<<<<<<< HEAD
                                        if (itemsData.gameCatCode
                                            && itemsData.gameCatCode.toLowerCase().indexOf('slot')===0) {
                                            // temp code
                                            Pushgtagdata('Slots_TopNav', 'Go to '+itemsData.provider+' Lobby', 'Slots_TopNav_C_' + itemsData.provider)
                                        }
=======*/
                                        sendProductGamePiwikEvent4Home.run3(itemsData.provider, itemsData.gameCatCode);

                                        this.openGame(itemsData);
                                    }}
                                    className={classNames({
                                        isNew: itemsData.isNew,
                                        isHot: itemsData.isHot,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            ["list-content"]: true,
                                        })}
                                    >
                                        {/* <img
                                            alt={itemsData.provider}
                                            src={itemsData.imageUrl}
                                            style={{ margin: "auto" }}
                                        /> */}
                                        <Image
                                            alt={itemsData.provider}
                                            src={itemsData.image || "/cn/img/logo.svg"}
                                            width={100}
                                            height={100}
                                            loading="lazy"
                                        />
                                        <p>{itemsData.gameName}</p>
                                    </div>
                                </a>
                            )}
                        {/* 普通游戏图片启动 */}
                        {itemsData && !itemsData.Type && !customHtml && (
                            <div
                                className="openGame"
                                onClick={e => {
                                    if (isGuest()) {
                                        // in order to trigger piwik, we do not: e.stopPropagation();
                                        global.goUserSign("1");
                                        global.IS_GUEST_CLICK_OPENGAME_BUT_POPUP_LOGIN_MODAL_FLASHSESSION = true; // p3 TASK-66107
                                        return;
                                    }
                                    this.openGame(itemsData);
                                }}
                            >
                                {!hideTag && (
                                    <Tag provider={itemsData.provider} category={itemsData.gameCatCode} />
                                )}
                                <ImageWithFallback
                                    src={itemsData.imageUrl}
                                    width={
                                        getImageDimensions(
                                            itemsData.gameCatCode
                                        ).width
                                    }
                                    height={
                                        getImageDimensions(
                                            itemsData.gameCatCode
                                        ).height
                                    }
                                    alt={itemsData.gameName}
                                    fallbackSrc="/cn/img/logo.svg"
                                />

                                <div className="GameName">
                                    {itemsData.gameName}
                                </div>
                            </div>
                        )}
                        {/* 自定义显示 */}
                        {customHtml && customHtml(this)}
                    </React.Fragment>
                )}

                <Mdl2025 isShow={this.state.showBalanceError}
                    onClick1={()=>{
                        this.setState({showBalanceError: false})
                        if(this.props.useCentralPayment){
                            handleDepositClick(this.props.dispatch, this.props.useCentralPayment);
                        }else{
                            global.showDialog({
                                key: 'wallet:{"type": "deposit"}',
                            });
                        }
                    }}
                    onClick2={()=>{
                                this.setState(
                                    {
                                        showBalanceError: false,
                                    },
                                    () => {
                                        if (
                                            this.IsNoIframe(
                                                getQueryVariable("name")
                                            )
                                        ) {
                                            this.props.openWindow();
                                            return;
                                        }
                                        let inMemoryDataAboutCheckBalanceModalOpenedFromIframeOpenGame = global.dataAboutCheckBalanceModalOpenedFromIframeOpenGame;
                                        delete global.dataAboutCheckBalanceModalOpenedFromIframeOpenGame;
                                        let inMemoryItemData = inMemoryDataAboutCheckBalanceModalOpenedFromIframeOpenGame ? inMemoryDataAboutCheckBalanceModalOpenedFromIframeOpenGame.theGameItem : undefined;
                                        this.actionGame(itemsData || inMemoryItemData, true);
                                    }
                                );
                            }}
                    btn1txt="立即存款"
                    btn2txt="先逛逛"
                    hd="余额提醒"
                    bd={'可用金额低于 ' + (global.MIN_MONEY_REQUIRED||10) + ' 元，为确保畅快的游戏体验，建议您及时存款'} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        useCentralPayment: state.centralPayment.useCentralPayment
	};
};


const mapDispatchToProps = function(dispatch) {
    return {
        dispatch,
        showSpin: (payload) => dispatch(showSpin(payload)),
        hideSpin: () => dispatch(hideSpin())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(openGame);
