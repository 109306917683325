import CryptoJS from 'crypto-js';

/**
 * 動態生成密鑰
 * @returns {string} 隨機生成的密鑰（16 位元組長度）
 */
export const generateSecretKey = () => {
    return CryptoJS.lib.WordArray.random(16).toString();
};

/**
 * 加密函數
 * @param {string} data - 需要加密的字串
 * @param {string} secretKey - 用於加密的密鑰
 * @returns {string} 加密後的字串
 */
export const encryptData = (data, secretKey) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
};