import { throttle } from '$ACTIONS/util';
import { isGuest } from '$ACTIONS/helper';
import {
    DEFAULT_PARAMS,
    getBalanceV2,
    getBalanceWalletGroup,
} from "../../actions/balanceRequest";
import { balanceActions } from "../balanceSlice";
// import store from "../store";
// const { gamesToFilter } = store.getState().game;

export function setBalanceV2Action(walletProductGroupId=DEFAULT_PARAMS.walletProductGroupId, call) {
    return async function (dispatch, getState) {
        if (isGuest()) { return; }
        try {
            dispatch(balanceActions.setUIIsLoading(true));

            const res = await getBalanceV2(walletProductGroupId+'&t='+(new Date().toJSON()));
            dispatch(balanceActions.setV2(res));

            dispatch(balanceActions.setUIIsLoading(false));

            if (call) {
            const storeState = getState();
            call(storeState.balance.v2);
            }
        } catch (error) {
            console.error(error.message);
        }
    };
}

export function setBalanceWalletGroupAction() {
    return async function (dispatch, getState) {
        if (isGuest()) { return; }
        try {
            dispatch(balanceActions.setUIIsLoading(true));

            const res = await getBalanceWalletGroup();
            dispatch(balanceActions.setWalletGroup(res));

            dispatch(balanceActions.setUIIsLoading(false));
        } catch (error) {
            console.error(error.message);
        }
    };
}
