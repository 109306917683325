/*
 * @Author: Alan
 * @Date: 2023-06-14 15:11:45
 * @LastEditors: Alan
 * @LastEditTime: 2023-09-12 09:31:55
 * @Description: 正则
 * @FilePath: /F1-M1-WEB-Code/actions/reg.js
 */
// 用户密码
export const pwdRegOld = /^(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9])+$/;
// export const pwdReg = /^(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\^#$@]*)([\^#$@]*[a-zA-Z0-9][\^#$@]*)+$/
// 统一App用户密码格式
export const pwdReg = /^(?=.{6,20}$)(?=.*[0-9])(?=.*[A-Za-z])[0-9A-Za-z\^\#\$\@]+$/;

export const phoneReg = /^(\+?86\-?)?1[0-9]{2}[0-9\*]{6}[0-9]{2}$/;

// 用户名登陆格式限制
export const nameReg = /^([a-zA-Z0-9]{5,16})$/;

// 禁止出现结尾空格
export const trimReg = /^\s+$/;

/**
 * 安全屋登陆API用户名密码正则
 * username ---> ^[a-zA-Z0-9]{5,16}$
 * password ---> ^(?=.{6,16}$)(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\^#\$@])+$
 */

// 用户真实姓名
export const realyNameReg = /^[\u4e00-\u9fa5\u0E00-\u0E7F\u00B7\u30FB\u2022\uFF65\u318D\u2027\u002E]{2,15}$/; // /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,15}$/;

// 充值账号后6位
export const sixLastNumReg = /^[0-9]{6}$/;

// OTP 6位
export const otpNumReg = /^[0-9]{6}$/;

// 存款金额限制小数后两位
export const depositMoneyDecimal = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
// 存款金额限制整数
export const depositMoneyInt = /^[0-9]*$/;

// Email 格式
export const emailReg = /^[A-Za-z0-9_]+[a-zA-Z0-9_\.\-]{0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

// 手機格式
export const regPhone = /[0-9]{11}/;

// QQ格式
export const regQQ = /^[0-9]*$/;

// 微信格式
export const regWeChat = /^[0-9a-zA-Z_]*$/;

// PT电子账户用户名格式
export const ptAccountReg = /^[a-zA-Z0-9]{6,16}$/;

// 登陆用户名密码格式限制
export const regforLogin = /^.+$/;
// 登陆用户名密码非法格式限制
export const regforLoginReg = /[<>\/\\="'\s.]/g;

// 身份证号码格式验证
// export const memberId = /^[1-9][0-9]{5}(19[0-9]{2}|200[0-9]|2010)(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[0-9]{3}[0-9xX]$/i;
export const memberId = /^[0-9]{17}[0-9X]$/i;

// 银行卡号基础验证
export const bankNumberReg = /^[0-9]{14,19}$/;

//地址 中英文 + 数字 +常用的标点符号
export const addressReg = /^[\u4E00-\u9FA5A-Za-z0-9\@\.\,\?\。\，\-\——\=\;\@\！\!\_\+]{2,100}$/;

//身份证  18位
export const idCard = /^[0-9]\d{16}(\d|x|X)$/;
