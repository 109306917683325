import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { isWebPSupported, AvifCheckerModule } from "$ACTIONS/helper";

export const CSS_CLASSNAME = 'newbie';
export const CSS_CLASSNAME2 = 'newbie-later';
export const IMG_BALANCE_DETAIL_NEWBIE = 'balance-dtl';

const NewbieLocalStorageKey = imgsrc => {
    let memberCode = localStorage.getItem('memberCode');
    if (memberCode) {
        const BACKEND_MEMBER_CODE_SUFFIX_LENGTH = 8;
        memberCode = memberCode.slice(memberCode.length - BACKEND_MEMBER_CODE_SUFFIX_LENGTH);
    } else {
        let d = new Date;
        memberCode = 'Guest' + d.getFullYear() + d.getMonth();
    }
    return memberCode + '_NEWBIE_' + imgsrc.replace(/\//g, '').split('.')[0]
};

const Newbie = ({ imgsrc, imgstyle={}, btnstyle={}, isClearBg=false, isHide=false }) => {
    const KEY = NewbieLocalStorageKey(imgsrc);

    const [imgsrc2, setImgsrc2] = useState(imgsrc);

    useEffect(()=>{
        AvifCheckerModule.isAvifSupportedPromise().then(b=>{
            if (b) {
                setImgsrc2(imgsrc2.replace(/\.png$/i, '.avif'));
            } else if (isWebPSupported()) {
                setImgsrc2(imgsrc2.replace(/\.png$/i, '.webp'));
            }
        });
    }, [imgsrc])

    if (localStorage.getItem(KEY)) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={isHide ? CSS_CLASSNAME2 : CSS_CLASSNAME} onClick={e=>{
            let $target = e.target;
            if (['IMG', 'BUTTON'].includes(e.target?.tagName?.toUpperCase())) {
                $target = e.target.parentElement
            }
            $target.style.display = 'none';
            $target.setAttribute('class', ''); // Disable html:has(div.newbie) CSS Selector
            localStorage.setItem(KEY, new Date().getTime());
        }}
        style={Object.assign({
            position:'fixed',display:'block',zIndex:99999,top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.6)'},
            isClearBg ? {} : {backdropFilter: 'blur(3px)',WebkitBackdropFilter: 'blur(3px)'},
            isHide ? {visibility:'hidden'} : {}
        )}>
            <img style={{...{cursor:'pointer',position:'absolute'}, ...imgstyle}} src={imgsrc2} />
            <button type="button" style={{...{cursor:'pointer', position:'absolute',border:'1px solid #00A6FF',background:'#00A6FF'
                ,lineHeight:'32px',height:32,width:90,textAlign:'center',color:'#fff',padding:0,borderRadius:'16px',marginLeft:-45,left:'50%'}, ...btnstyle}}>我知道了</button>
        </div>,
        document.getElementsByTagName('body')[0]
    );
};

export default Newbie;
