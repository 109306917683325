import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';

const SVG_UNCHECK = 'mdl-chkbox.svg';
const SVG_CHECKED = 'mdl-chkboxed.svg';
let timers = {};
export const renderMdl2025LabelAgreementCheckbox = (that, submitableKey, monitorIsShowMdl2025StateKey) => {
    return (<label style={{cursor:'pointer',marginTop:16,display:'block',textAlign:'left',lineHeight:1.2}} onClick={e=>{
      let $img, TAG = e.target.tagName.toUpperCase();
      if (TAG==='IMG') {
          $img = e.target
      } else if (TAG==='LABEL') {
          $img = e.target.getElementsByTagName('img')[0];
      } else if (TAG==='B') {
          $img = e.target.parentElement.getElementsByTagName('img')[0];
      }
      let imgsrc = $img.getAttribute('src'), submitable;
      if (imgsrc.includes(SVG_CHECKED)) {
          imgsrc = imgsrc.replace(SVG_CHECKED, SVG_UNCHECK);
          submitable = false;
      } else if (imgsrc.includes(SVG_UNCHECK)) {
          imgsrc = imgsrc.replace(SVG_UNCHECK, SVG_CHECKED);
          submitable = true;
      }
      //
      if (typeof submitable === 'boolean') {
          $img.setAttribute('src', imgsrc);
          that.setState({[submitableKey]: submitable});

          if (timers[monitorIsShowMdl2025StateKey]) {
              clearInterval(timers[monitorIsShowMdl2025StateKey])
          }
          if (submitable) {
              timers[monitorIsShowMdl2025StateKey] = setInterval(()=>{
                  if (!that.state[monitorIsShowMdl2025StateKey]) {
                      if (submitable) {
                          $img.parentElement.click(); // force to un-check <label />
                      }
                      clearInterval(timers[monitorIsShowMdl2025StateKey]);
                  }
              },500);
          }
      }
  }}><img src={"/cn/img/1wallet/05/" + SVG_UNCHECK} style={{float:'left',margin:'-1px 5px 0 0',borderBottom:'9px solid #fff'}} />
      我已阅读上述说明，并同意<b style={{color:'#222'}}>取消进行中的优惠</b></label>);
} // end of renderMdl2025LabelAgreementCheckbox

const Mdl2025 = ({
  kind=[],
  hd,
  bd,
  btn1txt,
  btn2txt,
  isShow,
  isBtnFullWidth,
  isShowX,
  onClick2,
  onClickX,
  isHideBtn1=false,
  isDisablePrimary=false,
  onClick1=()=>{} }) => {
    if (typeof document === 'undefined' // Just avoid trigger SSR error
    ) {
        return null;
    }

    return ReactDOM.createPortal(
        <div style={isShow ? {opacity:1,pointerEvents:'auto'} : {opacity:0,pointerEvents:'none'}} className={"mdl2025" + (kind.includes('NARROW') ? '' : ' WIDE') + (kind.includes('NEAR_W') ? ' NEAR_W' : '')}>
          <div className="mdl2025-container" style={{transform: isShow ? 'scale(1)' :''}}>
            {isShowX ? <div className="mdl2025-x">
              <img src="/cn/img/1wallet/mdl25-x.svg" onClick={()=>{
                if (onClickX==='SAME_AS_ONCLICK2' && onClick2) {
                  onClick2();
                  return;
                }
                onClickX && onClickX();
              }} />
            </div> : null}
            <h2 className="mdl2025-title">{hd}</h2>
            <div className="mdl2025-message">{bd}</div>
            <div className={"mdl2025-btns" + (isBtnFullWidth ? ' mdl2025-btns-full-w' : '')}>
            {isHideBtn1 ? null :
            <button {...isDisablePrimary && {disabled: 'disabled'}} onClick={()=>{
                onClick1 && onClick1();
            }} className="mdl2025-button primary">{btn1txt || '我知道了'}</button>
            }
            {onClick2 && <button className="mdl2025-button secondary" onClick={onClick2}>{btn2txt}</button>}
            </div>
          </div>
        </div>,
        document.getElementsByTagName('body')[0]
    );
};

export default Mdl2025;

