import React from "react";
import Router from "next/router";
import { createForm } from "rc-form";
import { Button, Input, message, Row, Spin, Col } from "antd";
import Item from "../../components/View/Formitem";
import { pwdReg, emailReg } from "$ACTIONS/reg";
import { get, post } from "$ACTIONS/TlcRequest";
import { getAffiliateReferralCode, Cookie, getSeonSession } from "$ACTIONS/helper";
import { ApiPort } from "$ACTIONS/TLCAPI";
import { normalizeBffscResponse, getTargetPhoneRules, isValidPhone, handlePhoneValidationUX } from "$ACTIONS/util";
import HostConfig from "$ACTIONS/Host.config";
import CMSOBJ from "$DATA/stage.live.static.id";
import Captcha from "../Captcha";
import {
    MemberIcon,
    PasswordIcon,
    PhoneIcon,
    EmailIcon,
    RecommendIcon,
} from "./RegisterIcnoSvg";
const userName_reg = /^[a-zA-Z0-9]{6,14}$/;
const password_reg =
    /^(?=.{6,20}$)(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[\^#$@]*)([\^#$@]*[a-zA-Z0-9][\^#$@]*)+$/;
const email_reg =
    /^[A-Za-z0-9_]+[a-zA-Z0-9_\.\-]{0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

class _Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserName: "",
            UserPwd: "",
            confirmUserPwd: "",
            UserPhone: "",
            EmailAccount: "",
            loading: false,
            affcode: "",
            disabled: false,
            showAffCode: true,
            captchaVisible: false,
            challengeUuid: "",
            emailSuggestions: [], // 推薦郵箱
            isEmailInputFocused: false //email的input 是否正在onFocus
        };

        this.phoneRules = undefined;
        this.isLoadedPhoneRules = false;
    }

    componentDidMount() {
        this.props.RefRegister && this.props.RefRegister(this);
        const isDisabled =
            Cookie.GetCookieKeyValue("CO_affiliate") != "undefined" &&
            Cookie.GetCookieKeyValue("CO_affiliate") != "";
        this.setState({
            showAffCode: !localStorage.getItem("queleaReferrerId"),
            affcode: getAffiliateReferralCode(),
            disabled: isDisabled,
        });

        get(ApiPort.PhonePrefix)
            .then((res) => {
                this.isLoadedPhoneRules = true;
                this.phoneRules = getTargetPhoneRules(res);
            })
            .catch((error) => {
                console.log("PhonePrefix error:", error);
            });
        global.Pushgtagpiwikurl && global.Pushgtagpiwikurl("register");
    }

    startRegister = () => {
        const {
            UserName,
            UserPwd,
            confirmUserPwd,
            UserPhone,
            EmailAccount,
            referer,
        } = this.state;
        const UserPhoneTEST =
            /^(0|86|17951)?(13[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|14[579]|19[0-9])[0-9]{8}$/;
        const UserNameT = /^([a-zA-Z0-9]{6,16})$/;
        const phoneLength = UserPhone.toString().length;
        if (UserName == "") {
            message.error("请输入用户名");
            return;
        }
        if (UserNameT.test(UserName) == false) {
            message.error(
                "用户名必须由6-16位长度的字母或数字组成，中间不得有读音符号、空格或下划线"
            );
            return;
        }
        if (UserPwd == "") {
            message.error("请输入密码");
            return;
        }
        // if (confirmUserPwd == '') {
        // 	message.error('请确认密码');
        // 	return;
        // }
        if (pwdReg.test(UserPwd) === false) {
            message.error(
                "密码必须包含6-16个字符，必须同时包含字母和数字（可以包含^#$@中的特殊字符）"
            );
            return;
        }
        // if (UserPwd !== confirmUserPwd) {
        // 	message.error('两次密码输入不一致');
        // 	return;
        // }

        if (
            !this.isLoadedPhoneRules
        ) {
            console.error("手机号前缀验证未加载完成");
            return;
        }

        const handled = handlePhoneValidationUX(UserPhone.toString(), this.phoneRules);
        if (handled) {
            let [z, s] = handled;
            if (z==='_NOT_LOADED_PHONE_RULES_YET_') {
                console.error(s)
                return;
            } else if (z==='_EMPTY_VALUE_') { // should not come here bcz empty phone is NOT able to submit
                message.error(s)
                return;
            } else if (Array.isArray(z)) {
                let [isHitPrefix, isMinLengthGood, isMaxLengthGood, ruleOfMinAndMax] = z;
                if (!isMinLengthGood || !isMaxLengthGood) {
                    message.error(s)
                    return;
                } else if (!isHitPrefix) {
                    message.error(s)
                    return;
                }
            }
        }

        if (
            /\D/.test(UserPhone.toString())
        ) {
            message.error("请输入正确的手机号");
            return;
        }

        if (!emailReg.test(EmailAccount)) {
            message.error("请输入正确的邮箱");
            return;
        }
        //this.getCaptchaSwitch();

        if (this.Captcha) {
			this.Captcha.getCaptchaInfo(UserName);
		}

        if (this.state.challengeUuid == '') {
            this.setState({ captchaVisible: true });
            return;
        }
        this.Register();
    };
    /***是否开启图形验证*/
    getCaptchaSwitch = () => {
        get(ApiPort.Captchaswitch).then((res) => {
            if (res) {
                if (res.isCaptchaOn) {
                    this.setState({ captchaVisible: true });
                }
            }
        });
    };
    /*注册*/
    Register() {
        const {
            UserName,
            UserPwd,
            confirmUserPwd,
            UserPhone,
            EmailAccount,
            referer,
        } = this.state;
        this.setState({ loading: true });
        const UserData = {
            hostName: ApiPort.LOCAL_HOST,
            regWebsite: 37,
            language: "zh-cn",
            mobile: "86-" + UserPhone,
            email: EmailAccount,
            username: UserName,
            mediaCode: Cookie.GetCookieKeyValue("CO_Media") || null,
            referer:
                Cookie.GetCookieKeyValue("CO_Referer") ||
                sessionStorage.getItem("affCode") ||
                null,
            affiliateCode: this.state.affcode,
            password: UserPwd,
            brandCode: "FUN88",
            currency: "CNY",
            queleaReferrerId: localStorage.getItem("queleaReferrerId"),
            challengeUuid: this.state.challengeUuid,
            deviceSignatureBlackBox: window.E2GetBlackbox
                ? window.E2GetBlackbox().blackbox == '' || window.E2GetBlackbox().blackbox == undefined
                    ? ''
                    : window.E2GetBlackbox().blackbox
            	: '',
        };

        const promise = getSeonSession();
        promise.then(seonSess => {
            UserData.seonSession = seonSess;
            console.log("UserData", UserData);
            return post(ApiPort.PostRegister, UserData, undefined, {IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx:true})
            })
            .then((res) => {
                if (res) {
                    if (res.IS_FEAT_MAINTENANCE) {
                        this.props.hidemodal(); // auto-hide parent modal component in order to let 503 maintenance modal to be readable
                    } else {
                    if (res.isSuccess == true) {
                        this.props.showmodal("1");
                        this.props.login(UserName, UserPwd, "Register");
                        //Pushgtagdata('web_Register');
                        // setTimeout(() => {
                        // 	this.SkipVerification();
                        // }, 1500);
                    } else if (res.Json && res.Json.isSuccess == false) {
                        let [code, msg, desc] = normalizeBffscResponse(res.Json)
                        message.error(desc || msg);
                    }
                    } // end of else-IS_FEAT_MAINTENANCE
                }
                this.setState({ loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false, challengeUuid: '' });
                console.log(error);
            });
    } // end of Register() {

    RegisterFormdata = (e, t) => {
        if (t == "name") {
            this.setState({
                UserName: e.target.value,
            });
        }
        if (t == "affcode") {
            this.setState({
                affcode: e.target.value,
            });
        }
        if (t == "pwd") {
            this.setState({
                UserPwd: e.target.value,
            });
        }
        // if (t == 'confirmpwd') {
        // 	this.setState({
        // 		confirmUserPwd: e.target.value
        // 	});
        // }
        if (t == "phone") {
            this.setState({
                UserPhone: e.target.value,
            });
        }
        if (t == "email") {
            this.setState({
                EmailAccount: e.target.value,
            });
        }
    };
    onMatch = (id) => {
        this.setState(
            {
                challengeUuid: id,
                captchaVisible: false,
            },
            () => {
                this.Register();
            }
        );
    };

    /**
     * @description: 如果通过验证 提交数据
     * @param {*}
     * @return {*}
     */

    submitBtnEnable = () => {
        let error = Object.values(this.props.form.getFieldsError()).some(
            (v) => v !== undefined
        );
        let errors = Object.values(this.props.form.getFieldsValue()).some(
            (v) => v == "" || v == undefined
        );
        return !errors && !error;
    };

    	/**
	 * @description: 監聽電子郵箱輸入的變化
	 * @param {*}
	 * @return {void}
  	*/

	handleEmailChange = (e) => {
		const value = e.target.value;
		const atIndex = value.indexOf('@');

		// 如果用戶輸入了 "@"
		if (atIndex !== -1) {
			const namePart = value.slice(0, atIndex); // 獲取 "@"之前的部分
			const domainPart = value.slice(atIndex + 1);
			const emailSuffixes = [
				"qq.com",
				"163.com",
				"sina.com",
				"sohu.com",
				"126.com",
				"139.com",
				"wo.cn",
				"hotmail.com",
				"msn.com",
				"outlook.com"
			];

			// 將namePart和推薦的網域部分結合起來
			const suggestions = emailSuffixes.filter(suffix => suffix.startsWith(domainPart))
											.map(suffix => `${namePart}@${suffix}`);

			this.setState({ EmailAccount: value, emailSuggestions: suggestions });
		} else {
			this.setState({ EmailAccount: value, emailSuggestions: [] });
		}
	}

	/**
	 * @description: 處理點擊建議
	 * @param {*}
	 * @return {void}
  	*/

	handleSuggestionClick = (suggestion) => {
		this.setState({ EmailAccount: suggestion, emailSuggestions: [] });

		this.props.form.setFieldsValue({ emailNameState: suggestion });

		//手動觸發表單驗證
		this.props.form.validateFields(['emailNameState']);
	}

    render() {
        const { affcode, captchaVisible } = this.state;
        const { getFieldDecorator, getFieldError } = this.props.form;
        return (
            <Spin
                spinning={this.state.loading}
                tip="正在注册中..."
                style={{ backgroundColor: "initial" }}
            >
                <div className="user-modal">
                    {/* ------------------ 用戶名 ------------------*/}
                    <Item errorMessage={getFieldError("NameState")}>
                        <div className="IputBox">
                            {getFieldDecorator("NameState", {
                                rules: [
                                    { required: true, message: "请输入用户名" },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (
                                                value &&
                                                !userName_reg.test(value)
                                            ) {
                                                callback(
                                                    "用户名长度必须至少有6个字符，不能超过14个字符，仅可使用字母 'A-Z', 'a-z' , 数字 '0-9'。"
                                                );
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder="用户名"
                                    prefix={<MemberIcon />}
                                    onChange={(e) =>
                                        this.RegisterFormdata(e, "name")
                                    }
                                    maxLength={14}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 密碼 ------------------*/}
                    <Item errorMessage={getFieldError("passwordState")}>
                        <div className="IputBox">
                            {getFieldDecorator("passwordState", {
                                rules: [
                                    { required: true, message: "请输入密码" },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (
                                                value &&
                                                !password_reg.test(value)
                                            ) {
                                                callback(
                                                    "密码必须包含6-20个字符，字符只限于使用字母和数字。（可以包含 ^＃$@ 中的特殊字符）。"
                                                );
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input.Password
                                    size="large"
                                    prefix={<PasswordIcon />}
                                    placeholder="密码"
                                    onChange={(e) =>
                                        this.RegisterFormdata(e, "pwd")
                                    }
                                    maxLength={20}
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 确认密碼 ------------------*/}
                    {/* <div className="IputBox">
						<Input.Password
							size="large"
							placeholder="请确认密码"
							onChange={(e) => this.RegisterFormdata(e, 'confirmpwd')}
							maxLength={16}
						/>
					</div> */}

                    {/* ------------------ 联系电话 ------------------*/}
                    <Item errorMessage={getFieldError("numberState")}>
                        <div className="IputBox">
                            {getFieldDecorator("numberState", {
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入联系电话",
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            const handled = handlePhoneValidationUX(value, this.phoneRules);
                                            if (handled) {
                                                let [z, s] = handled;
                                                if (z==='_NOT_LOADED_PHONE_RULES_YET_') {
                                                    console.error(s)
                                                    return;
                                                //} else if (z==='_EMPTY_VALUE_') {
                                                //    message.error(s)
                                                //    return;
                                                } else if (Array.isArray(z)) {
                                                    let [isHitPrefix, isMinLengthGood, isMaxLengthGood, ruleOfMinAndMax] = z;
                                                    if (!isMinLengthGood || !isMaxLengthGood) {
                                                        callback(s)
                                                    } else if (!isHitPrefix) {
                                                        callback(s)
                                                    }
                                                }
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    // addonBefore="+86"
                                    className="registerPhone"
                                    prefix={<PhoneIcon text={"+ 86"} />}
                                    placeholder=" 联系电话"
                                    onChange={(e) =>
                                        this.RegisterFormdata(e, "phone")
                                    }
                                />
                            )}
                        </div>
                    </Item>

                    {/* ------------------ 电子邮箱 ------------------*/}
                    <Item errorMessage={getFieldError("emailNameState")}>
                        <div className="IputBox">
                            {getFieldDecorator("emailNameState", {
                                rules: [
                                    {
                                        required: true,
                                        message: "请输入电子邮箱",
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (
                                                (value &&
                                                    !email_reg.test(value)) ||
                                                value.length > 100
                                            ) {
                                                callback("请输入有效的电子邮箱");
                                            }
                                            callback();
                                        },
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    prefix={<EmailIcon />}
                                    placeholder="电子邮箱"
                                    onChange={this.handleEmailChange}
                                    maxLength={50}
                                    onFocus={() => this.setState({ isEmailInputFocused: true })}
                                    onBlur={() => this.setState({ isEmailInputFocused: false })}
                                />
                            )}
                                    {this.state.emailSuggestions && this.state.isEmailInputFocused &&
                                        <ul className="email-suggestions">
                                            {this.state.emailSuggestions.map((suggestion, index) => (
                                                <li key={index}
                                                    className="email-suggestion"
                                                    onMouseDown={() => this.handleSuggestionClick(suggestion)}
                                                >
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    }

                        </div>
                    </Item>
                    {/* ------------------ 推荐代码 ------------------*/}
                    {this.state.showAffCode ? (
                        <div className="IputBox fix-not-required-text">
                            <Input
                                size="large"
                                prefix={<RecommendIcon />}
                                placeholder="推荐代码"
                                disabled={this.state.disabled ? true : false}
                                defaultValue={affcode}
                                value={affcode}
                                key={this.state.disabled ? affcode : ""}
                                onChange={(e) =>
                                    this.RegisterFormdata(e, "affcode")
                                }
                                maxLength={16}
                            />
                            <div className="not-required-text">(非必填)</div>
                        </div>
                    ) : null}

                    {/* ------------------ 注册 ------------------*/}
                    <Button
                        size="large"
                        type="primary"
                        block
                        onClick={() => {
                            this.startRegister();
                            Pushgtagdata(
                                "Register",
                                "Submit",
                                "Submit_Register"
                            );
                        }}
                        disabled={!this.submitBtnEnable()}
                    >
                        注册
                    </Button>
                </div>

                <div className="protocol-box">
                    <p className="protocol">
                        点击“注册”即确认您已年满21周岁,且理解并接受我们的
                        <a
                            onClick={() => {
                                Router.push(
                                    "/cn/Help?type=Sub7&key=" +
                                        CMSOBJ[HostConfig.CMS_ID][22]
                                ).then(()=> {
                                    this.props.closeModal && this.props.closeModal();
                                });
                                Pushgtagdata("Agreement_register");
                            }}
                        >
                            条款
                        </a>
                        与
                        <a
                            onClick={() => {
                                Router.push(
                                    "/cn/Help?type=Sub7&key=" +
                                        CMSOBJ[HostConfig.CMS_ID][21]
                                ).then(()=> {
                                    this.props.closeModal && this.props.closeModal();
                                });
                                Pushgtagdata("Agreement_register");
                            }}
                        >
                            隐私政策
                        </a>
                    </p>
                </div>

                <Captcha
                    captchaVisible={captchaVisible}
                    setCaptchaVisible={(v) => {
                        this.setState({ captchaVisible: v });
                    }}
                    onMatch={this.onMatch}
                    getCaptchaInfo={(props) => {
						this.Captcha = props;
					}}
                />
            </Spin>
        );
    }
}

export default createForm({ fieldNameProp: "register" })(_Register);
