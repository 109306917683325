import { ApiPort } from "$ACTIONS/TLCAPI";
import { advget, advpost } from "$ACTIONS/TlcRequest";
import { getE2BBValue, eliminateBackendResponseRedundantKeyInResultField } from "$ACTIONS/helper";

const MEMBER_NOTIFICATION_CATEGORY_5 = '5';
const MEMBER_NOTIFICATION_CATEGORY_6 = '6';
const MEMBER_NOTIFICATION_CATEGORY_7 = '7';
const MEMBER_NOTIFICATION_CATEGORY_8 = '8';
const MEMBER_NOTIFICATION_CATEGORY_999 = '999';
export const enumBonusStatus = Object.assign(Object.create({
    displayStatusTextAndIconForPMA: function(catId, status) {
        let txt = '---', ico = '';
        if (MEMBER_NOTIFICATION_CATEGORY_5 == catId || MEMBER_NOTIFICATION_CATEGORY_7 == catId || MEMBER_NOTIFICATION_CATEGORY_8 == catId) { // 5 7 8
            txt = '已完成'
            ico = 'ico-green.svg'
        } else if (MEMBER_NOTIFICATION_CATEGORY_999 == catId) { //999
            txt = '已取消'
            ico = 'ico-red.svg'
        } else if (MEMBER_NOTIFICATION_CATEGORY_6 == catId) { //6
            txt = '已过期'
            ico = 'ico-red.svg'
        }
        return [txt, ico];
    }
}), {
    AllStatus:{ID:0},
    Pending:{ID:1},
    Cancelled:{ID:2},//999
    InProgress:{ID:3},
    Processing:{ID:4}, // === Waiting For Release
    ForcedToServed:{ID:5}, //8
    Complete:{ID:6}, //7
    ClickToClaim:{ID:7}, //5
    Expired:{ID:8}, //6
    RequiredCheck:{ID:9},
});

/* m2mob
	let bonusStatusType = null;
    switch(String(messageDetailItem?.memberNotificationCategoryId)){
    case "5": //claimable (BonusSuccess)
        bonusStatusType = "claimable";
        break;
    case "6": //complted (BonusExpired)
    case "7": //complted (BonusServed)
    case "8": // complted (BonusForcedServe)
    case "999": // complted (BonusCancelled)
        bonusStatusType = "completed";
        break;
    case "201":
        bonusStatusType = "readyToStart";
        break;
    case "202":
        bonusStatusType = "fail";
        break;
    default:
        return;
    }
*/

export const BonusListingMode = {
    DEPOSIT:{val:'Deposit'},
    TRANSFER:{val:'Transfer'}
};

const enumPartnerBonusType = {
    PRE: 'PRE',
    POST: 'POST',
}
export const enumBonusRuleType = {
    PRE: 'PRE',
    POST: 'POST',
}
const enumContractCategory = {
    REBATE: 'REBATE',
    PREBONUS: 'PREBONUS',
    POSTBONUS: 'POSTBONUS',
    ADJUSTMENT: 'ADJUSTMENT',
    REFERRAL: 'REFERRAL',
}

const textContractCategoryMapping = {
    REBATE: '返水红利',
    PREBONUS: null,
    POSTBONUS: null,
    REWARDPOINT: '乐币兑换彩金',
    ADJUSTMENT: '系统金额调整',
    REFERRAL: '推荐好友',
};

const normalizeContractCategory = item => {
    let C = typeof item.contractCategory==='string' ? item.contractCategory.toUpperCase() : '';
    return C;
}

const isXxxx = XXXX => item => {
    let C = normalizeContractCategory(item);
    return C===XXXX;
}
const isRebate = isXxxx('REBATE');
export const isPrebonus = isXxxx('PREBONUS');
// export const isPostbonus = isXxxx('POSTBONUS');
// export const isAdjustment = isXxxx('ADJUSTMENT');
// export const isReferral = isXxxx('REFERRAL');

export const getBonusTextInTable = item => {
    let C = normalizeContractCategory(item);
    let ret = textContractCategoryMapping[C] || item.title;
    if (!ret && isRebate(item)) {
        // might not enter here, just follow mockup description
        ret = '返水彩金';
    }
    return ret;
};

export const ALL_WALLET_PRODUCT_GROUP_ID = 1;

//{"bonusId": 175843, "playerBonusId": 2000001045, "bonusTitle": "Core Pre Bonus Normal Bonus SLOT 12", "creditAccountId": 124, "creditAccount": "游戏", "bonusGiven": "11.10", "bonusStatus": "已过期", "bonusStatusId": 11, "bonusStatusIdDescription": "Served", "appliedDate": "2023-11-07T16:19:40.778", "expriyDate": "2023-11-17T16:19:40.778", "isClaimable": false, "bonusCategory": 3, "bonusType": "锁定彩金", "percentage": 0, "progressText": "0.00 %", "bonusReleaseType": "TO:", "bonusReleaseTypeValue": "0/122.1", "partnerBonusType": "PRE", "statusTipsMessage": "" },
export const isPostPartnerBonusType = item => typeof item?.partnerBonusType==='string' && item.partnerBonusType.toUpperCase()===enumPartnerBonusType.POST;
export const isPrePartnerBonusType = item => typeof item?.partnerBonusType==='string' && item.partnerBonusType.toUpperCase()===enumPartnerBonusType.PRE;

export const isPostBonusRuleType = item => typeof item?.bonusRuleType==='string' && item.bonusRuleType.toUpperCase()===enumBonusRuleType.POST;
export const isPreBonusRuleType = item => typeof item?.bonusRuleType==='string' && item.bonusRuleType.toUpperCase()===enumBonusRuleType.PRE;


export async function getBonusProgress() {
    try {
        return await advget(ApiPort.GETBonusProgress);
    } catch (error) {
        console.error(error);
        return null;
    }
}

/*
export async function getBonusApplications() {
    try {
        return await advget(ApiPort.GETBonusApplications1);
    } catch (error) {
        console.error(error);
        return null;
    }
}*/

// 34bd7c9cf8669b974865f037b4ad559a1a7eb8fd
export const getBonusApplicationsEligible = (()=>{
    const f = async (BonusGroupId) => {
        try {
            return await advget(ApiPort.GETBonusApplicationsEligible + BonusGroupId);
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    f.isEligible = res => res && res.isSuccess && res.result?.isEligible;
    return f;
})();

export const getAppliedHistory = (()=>{
    const f = async (dateFrom, dateTo, pageIndex, perPageSize, bonusStatusArray) => {
        try {
            let qsPager = '';
            if (pageIndex || perPageSize) {
                if (!pageIndex) {
                    pageIndex = 1;
                }
                if (pageIndex && perPageSize) {
                    qsPager = `&pageIndex=${pageIndex}&perPageSize=${perPageSize}`;
                } else { // only pageIndex without perPageSize value
                    qsPager = `&pageIndex=${pageIndex}`
                }
            }
            let u = ApiPort.AppliedHistory + `&dateFrom=${dateFrom}&dateTo=${dateTo}` + qsPager;
            if (Array.isArray(bonusStatusArray) && bonusStatusArray.length>0) {
                bonusStatusArray = bonusStatusArray.join(',');
            }
            if (bonusStatusArray) {
                u += '&bonusStatusArray=' + bonusStatusArray;
            }
            const res = await advget(u);
            return qsPager
                ? eliminateBackendResponseRedundantKeyInResultField(res, 'bonusAppliedHistories')
                : res;
        } catch (error) {
            console.error(error);
            return null;
        }
    }
    return f;
})();

export const checkSignUpBonus = (()=>{
    const f = async (productGroup) => {
        try {
            if (!productGroup) {
                alert('lack productGroup');
                return;
            }
            return await advget(ApiPort.GETMemberServingSignupBonusStatus + productGroup);
        } catch (e) {
            console.error(e)
            return;
        }
    };
    f.isSignupBonus = res => res && res.isSuccess && res.result;
    return f;
})();

export const getPreBonusCancellationEligibility = (()=>{
    const f = async (playerBonusId) => {
        try {
            let res = await advget(ApiPort.GETBonusCancellationEligibility + '&playerBonusId=' + playerBonusId);
            if (Object.prototype.hasOwnProperty.call(res.result||{}, 'data')) {
                res.result = Object.assign({}, res.result, res.result.data);
            }
            return res;
        } catch (e) {
            console.error(e)
            return;
        }
    };
    f.getCaseResult = res => { // implement image-20240412-035524.png table; TODO: re-check
        let {
            result = res.result || {},
            isTurnoverProgress = result.isTurnoverProgress,
            applyAmount = result.applyAmount, //本金
            bonusGivenAmount = result.bonusGivenAmount, //紅利
            winningAmount = result.winningAmount, //盈利
            finalBalance = applyAmount + bonusGivenAmount + winningAmount,
            isLose = isTurnoverProgress && winningAmount < 0,
            isWin = isTurnoverProgress && winningAmount > 0,
            isNotLose = isTurnoverProgress && winningAmount >= 0,
            isNoBet2WinLose = !isTurnoverProgress,
            canceledFinalBalance = isNotLose
                                    ? applyAmount
                                    : Math.max(0, applyAmount + winningAmount),
            content,
            tooltip,
        } = {};

        return {
            isTurnoverProgress,
            applyAmount, //本金
            bonusGivenAmount, //紅利
            winningAmount, //盈利
            finalBalance,
            isLose,
            isWin,
            isNotLose,
            isNoBet2WinLose,
            canceledFinalBalance,
            content,
            tooltip,
        };
    }
    return f;
})();

export async function refreshBonusPreview(wallet, bonusId_bonusRuleGroupId, amount, MAINBalance=0) {
    try {
        let [bonusId, bonusRuleGroupId] = bonusId_bonusRuleGroupId;
        let body = {
            bonusMode: BonusListingMode.DEPOSIT.val,
            wallet,
            bonusId,
            bonusRuleGroupId,
            amount,
            memberBalance: MAINBalance
        };
        return await advpost(ApiPort.POSTBonusRefreshBonusPreview, body)
    } catch (e) {
        console.error(e);
        return e; // TODO: consider `e instanceof ReferenceError`  `e instanceof Error`
    }
}

export const createBonusApplications = (()=>{
    const f = async (bonusId_bonusGroupId, amount, targetWallet) => {
        // open me if need opt = opt || {}; // isMax couponCode
        let e2BlackBoxValue = getE2BBValue(), [bonusId, bonusGroupId] = bonusId_bonusGroupId;
        let body = {
            e2BlackBoxValue,
            blackBox: e2BlackBoxValue,
            bonusId,
            bonusGroupId,
            amount,
            targetWallet
        };
        /* no need: 19:6584b4fd8db6419aa614ff3b2adaea12@thread.v2/1738833546571?context=%7B%22contextType%22%3A%22chat%22%7D
        if (Object.prototype.hasOwnProperty.call(opt, 'bonusMode')) {
            body.bonusMode = opt.bonusMode;
        }
        if (body.bonusMode==='Transfer') {
            // dead code
            body.transferBonus = {
                fromWallet: 'MAIN',
                transactionId: '12412341264',
                isFreeBet: false
            };
        } else if (body.bonusMode==='Deposit') {
            body.depositBonus = {
                depositCharges: 2,
                depositId: '12412341264',
            };
            // body.successBonusId = 1412356856; // sample request does not present successBonusId
        }
        */
        /* open me if need
        if (Object.prototype.hasOwnProperty.call(opt, 'couponCode')) {
            body.couponCode = opt.couponCode;
        }
        */
        return await advpost(ApiPort.POSTBonusApplications, body);
        // /wiki/x/KABoGg
    }
    f.isApplicationOK = res => res && res.isSuccess && typeof res.result?.bonusResult?.message==='string' && res.result?.bonusResult?.message.toLowerCase()==='success';
    return f;
})();

export const cancelBonus = (()=>{
    const f = async (bonusId, playerBonusId) => {
        try {
            return await advpost(ApiPort.POSTBonusCancellation + '&playerBonusId=' + playerBonusId);
        } catch (e) {
            console.error(e)
            return;
        }
    }
    f.isCancelSuccess = res => res.isSuccess && res.result?.isSuccess;
    return f;
})();

export const claimBonus = (()=>{
    const f = async (playerBonusId) => {
        return await advpost(ApiPort.POSTBonusClaim, {playerBonusId});
    };
    f.isClaimed = res => res.isSuccess && res.result?.isClaimed;
    return f;
})();