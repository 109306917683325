import React from "react";
import { connect } from "react-redux";
import { ApiPort } from '$ACTIONS/TLCAPI';
import withIframeHandler from "../withIframeHOC";

class CentralPaymentIframe extends React.Component {

  handleIframeLoad = () => {
    if (this.props.onIframeLoaded) {
      this.props.onIframeLoaded();
    }
  };

  render() {
    const { iframeRef, theme, platform, type, modalType, token, hostDomain } = this.props

    const pathSegment = modalType === "deposit" ? "deposits" : "withdrawal";
    const tutorialType = modalType === "deposit" ? "deposit" : "withdrawal";
    const iframeUrl = `${ApiPort.CentralPaymentHost}${pathSegment}?platform=${platform}&brand=F1&theme=light&tutorialType=${tutorialType}&vt=${token}&hostDomain=${hostDomain}`;

    return (
      <div
        id={`${type === "deposit" ? "Deposit" : "Withdrawal"}Content`}
        style={{ width: "100%", height: "100%", overflow: "hidden" }}
      >
        <iframe
          ref={iframeRef}
          src={iframeUrl}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            overflow: "hidden",
            maxWidth: "100vw",
          }}
          title={type}
          onLoad={this.handleIframeLoad}
          allowFullScreen
        />
      </div>
    );
  }
}

// 映射 Redux state 到 props
const mapStateToProps = (state) => ({
  modalType: state.centralPayment.modalType,
});

// 使用 connect 連接 Redux store
export default connect(mapStateToProps)(withIframeHandler(CentralPaymentIframe));