/*
 * @Author: Alan
 * @Date: 2023-06-02 18:21:35
 * @LastEditors: Alan
 * @LastEditTime: 2023-07-01 09:16:25
 * @Description: 游戏相关数据
 * @FilePath: /F1-M1-WEB-Code/store/thunk/gameThunk.js
 */
import {
    GetCategoryList,
    GetFlashProviderList,
    GetCmsProviderList, GetSmarticoToggle,
    GetGameWhitelistStatus,
    GetCentralPaymentIsActive
} from "../../actions/gameRequest";
import { gameActions } from "../gameSlice";
import { mainSiteConfigActions } from "../mainSiteConfigSlice";
import { toggleCentralPayment } from "../centralPaymentSlice";
import store from "../store";
const { gamesToFilter } = store.getState().game;
import HostConfig from "$ACTIONS/Host.config";
/**
 * @description: 获取CMS-Api（优势介绍文案信息相关） 和 Flash-Api （游戏菜单数据分类导航相关）菜单数据 合并
 * @param {*} selectedCategory
 * @param {*} stopLoading
 * @return {*}
 */
export function getGameCategoryListAction(selectedCategory, stopLoading) {
    return async function getGameListThunk(dispatch, getState) {
        dispatch(gameActions.setLoading(true));
        try {
            const GameList = await GetCategoryList();
            if (!GameList) {
                dispatch(gameActions.setGameCategoriesData([]));
                return;
            }
            dispatch(gameActions.setGameCategoriesData(GameList));

            // 存储所有 Async Thunk 的 Promise
            const promises = [];
            promises.push(GameList)
            // GameList.forEach((item) => {
            //     promises.push(
            //         dispatch(
            //             getCmsProviderListAction(item.code)
            //         )
            //     );
            // });

            // 等待所有 Async Thunk 完成并收集它们的结果

            const results = await Promise.all(promises);
            if (results) {
                const providerListData = results.reduce(
                    (acc, result, index) => {
                        const item = GameList[index];
                        acc[item.code] = result;
                        return acc;
                    },
                    {}
                );
                console.log("providerListData", providerListData);
                dispatch(gameActions.setGameProviderListData(providerListData));
                localStorage.setItem(
                    "CmsProviderArrayList",
                    JSON.stringify(providerListData)
                );
            }
            dispatch(gameActions.setLoading(false));
        } catch (e) {
            console.error("thunk getGameList error", e.message);
        } finally {
            stopLoading && stopLoading();
        }
    };
}

/**
 * @description: 获取 CMS Provider,目的是为了那CMS数据的图片和一些文本信息
 * @param {*} categoryType 游戏种类
 * @param {*} gameCatId 游戏种类ID
 * @return {*}
 */
export function getCmsProviderListAction(categoryType, gameCatId) {
    return async function getCmsProviderListThunk(dispatch, getState) {
        try {
            const CmsProviderList = await GetCmsProviderList(
                categoryType,
                gameCatId
            );

            localStorage.setItem(
                `${categoryType}Provider`,
                JSON.stringify(CmsProviderList)
            );

            return CmsProviderList;
            // 返回结果给 Promise.all()
        } catch (e) {
            console.error(e.message);
        }
    };
}

/**
 * @description: 获取 CMS Provider,目的是为了那CMS数据的图片和一些文本信息
 * @param {*} categoryType 游戏种类
 * @param {*} gameCatId 游戏种类ID
 * @return {*}
 */
export function getCmsProviderAction(categoryType, gameCatId) {
    return async function getCmsProviderThunk(dispatch, getState) {
        try {
            const localProvider =
                JSON.parse(
                    localStorage.getItem(`${categoryType}Provider`) || "[]"
                ) || [];
            if (localProvider) {
                dispatch(
                    gameActions[`${categoryType}SetCmsProviderData`](
                        localProvider
                    )
                );
            } else {
                dispatch(getCmsProviderListAction(categoryType, gameCatId));
            }
        } catch (e) {
            console.error(e.message);
        }
    };
}

/**
 * @description: 获取 FlashProvider,目的是为了获得平台列表
 * @param {*} categoryType 游戏种类
 * @param {*} gameCatId 游戏种类ID
 * @return {*}
 */
export function getFlashProviderListAction(categoryType, isShowFishingGames) {
    return async function (dispatch, getState) {
        try {
            const providerData = await GetFlashProviderList(categoryType, isShowFishingGames);

            const filteredData = providerData.filter(
                (provider) => !gamesToFilter.includes(provider.providerCode)
            );
            const savedProviders =
                JSON.parse(localStorage.getItem(`${categoryType}Provider`)) ||
                [];

            const updatedProviderData = filteredData.map((item) => {
                const matchingSavedProvider = savedProviders.find(
                    (savedItem) => savedItem.providerCode === item.providerCode
                );

                if (matchingSavedProvider) {
                    return {
                        ...item,
                        imageUrl: matchingSavedProvider.providerImageUrl,
                        providerDesc: matchingSavedProvider.providerDesc,
                        providerSubtitle:
                            matchingSavedProvider.providerSubtitle,
                        providerTitle: matchingSavedProvider.providerTitle,
                        providerClass: matchingSavedProvider.providerClass,
                    };
                } else {
                    return item;
                }
            });

            dispatch(
                gameActions[`set_${categoryType}_ProviderData`](
                    updatedProviderData
                )
            );
        } catch (error) {
            console.error(error.message);
        }
    };
}

/**
 * @description: 设置隐藏游戏
 * @param {*} gameCatId 游戏种类
 * @return {*}
 */
export function setGameHideAction(gameCat) {
    return async function updateGameHideThunk(dispatch, getState) {
        await dispatch(gameActions.updateGameHide(gameCat));
        // await dispatch(getGameCategoryListAction());
        // await dispatch(getFlashProviderListAction("Sportsbook"));
    };
}

/**
 * @description: 重置游戏隐藏
 * @param {*} gameCatId 游戏种类
 * @return {*}
 */
export function setResetGameHideAction() {
    return function updateResetGameHideThunk(dispatch, getState) {
        dispatch(gameActions.resetData());
    };
}

/**
 * @description: smartico toggle
 * @return {*}
 */
export function getSmarticoToggleAction() {
    return async function getSmarticoToggleThunk(dispatch, getState) {
        try {
            const res = await GetSmarticoToggle();
            dispatch(gameActions.updateSmarticoVisible(res?.result?.smarticoIsActive || false));
            dispatch(mainSiteConfigActions.setAffiliateUrl(res?.result?.affiliateUrl || 'https://www.h32lucky.com/zh-cn'));
        } catch (e) {
            dispatch(gameActions.updateSmarticoVisible(false));
            console.error(e.message);
        }
    };
}

export function getCentralPaymentAction() {
    return async function getCentralPaymentThunk(dispatch, getState) {
        try {
            const res = await GetCentralPaymentIsActive();
            const centralPaymentActive = res?.result ?? false;
            handleCentralPayment(dispatch, centralPaymentActive)
        } catch (e) {
            console.error(e.message);
        }
    };
}


const handleCentralPayment = (dispatch, centralPaymentActive = null) => {
    if (!dispatch || typeof dispatch !== "function") return;

    try {
        const { isLIVE } = HostConfig.Config;
        const isCentralPaymentTestDomain =
        typeof window !== "undefined" &&
        window.location.hostname === "www.woaibw228.com";

        // domain 為 woaibw228.com 強制開啟 central-payment
        const forceEnable = isCentralPaymentTestDomain;

        /*
            如 forceEnable 為 true，直接開啟 central-payment。
            如 forceEnable 為 false：
            - 若 centralPaymentActive 為 null，設為 false。
            - 否則，若 centralPaymentActive 是布林值，使用該值；若不是，設為 false。
        */
        if (forceEnable) {
            dispatch(toggleCentralPayment(true));
        } else if (centralPaymentActive === null) {
            dispatch(toggleCentralPayment(false));
        } else {
            const isValidBoolean = typeof centralPaymentActive === "boolean";
            dispatch(toggleCentralPayment(isValidBoolean ? centralPaymentActive : false));
        }
    } catch (error) {
        console.log("handleCentralPayment error:", error);
        if (dispatch && typeof dispatch === "function") {
            dispatch(toggleCentralPayment(false));
        }
    }
};

/**
 * @description: Smartico Modal
 */
export function setSmarticoErrorModalAction(flag) {
    return function smarticoErrorModalActionThunk(dispatch, getState) {
        dispatch(gameActions.smarticoErrorModalVisible(flag));
    };
}

/**
 * @description: Smartico Login continue
 */
export function setSmarticoLoginContinue(isFromSmarticoLogin) {
    return function smarticoLoginContinueActionThunk(dispatch, getState) {
        dispatch(gameActions.smarticoLoginContinue(isFromSmarticoLogin));
    };
}

/**
 * @description: Check YBS whitelist status
 * @param {*} memberCode
 * @return {*}
 */
export function checkYBSWhitelistStatus(memberCode) {
    return async function checkYBSWhitelistStatusActionThunk(dispatch, getState) {
        try {
            const res = await GetGameWhitelistStatus('YBS', memberCode);
            if(!res) {
                // If NOT whitelisted, hide YBS
                dispatch(gameActions.updateGameHide('YBS'));
            }
        } catch (error) {
            console.error("Failed to check whitelist status:", error);
        } finally {
            dispatch(gameActions.setMemberCheckedYBS(true));
        }
    };
}