import React from "react";

export const MIN_PAGE = 1;

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  return (
    <div className="pagination">
      <button
        className={`pagination-arrow ${currentPage === 1 ? "disabled" : ""}`}
        onClick={handlePrevClick}
        disabled={currentPage === 1}
      >
        <img src="/cn/img/1wallet/pgr-r.svg" style={{transform:'rotate(180deg)'}} />
      </button>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
        <button
          key={page}
          className={`pagination-button ${
            currentPage === page ? "active" : ""
          }`}
          onClick={() => handlePageClick(page)}
        >
          {page}
        </button>
      ))}
      <button
        className={`pagination-arrow ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
      >
        <img src="/cn/img/1wallet/pgr-r.svg" />
      </button>
    </div>
  );
};

export default Pagination;

