import React from "react";
import { post } from "$ACTIONS/TlcRequest";
import { ApiPort, APISETS } from "$ACTIONS/TLCAPI";
import { Button, message, Input, Form, Modal, Tabs } from "antd";
import { getQueryVariable } from "$ACTIONS/helper";
const TabPane = Tabs.TabPane;
message.config({
    top: 100,
    duration: 2,
    maxCount: 1,
});
const emailRegex =
    /^[A-Za-z0-9_]+[a-zA-Z0-9_\.\-]{0,}@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
const regSymbols = /[\s]/;
class ForgotPwd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NEWPWD: "",
            OLDPWD: "",
            visible: false,
            Name: "",
            Email: "",
            EmailForForgetUser: "",
            loading: false,
            defaultkey: "1",
            activeIndex: "1",
            errorname: "",
            erroremail: "",
            erroremailForForgetUser: "",
            errorMessage: "",
            errorMessageForForgetUser: "",
            submitstatus: false,
            invalidInput: true,
            invalidInputForForgetUser: true,
        };
        this.UpdateMemberPWD = this.UpdateMemberPWD.bind(this);
        this.MemberChange = this.MemberChange.bind(this);
        this.EmailForgetPassword = this.EmailForgetPassword.bind(this);
        this.Forgetuser = this.Forgetuser.bind(this);
    }
    componentDidMount() {
        this.setState({
            defaultkey: this.props.Forgotkey,
        });

        const actionParam = getQueryVariable("action");
        actionParam === "forget" && this.showModal(1);
    }

    MemberChange(type, e) {
        if (type == "OLDPWD") {
            this.setState({ OLDPWD: e.target.value });
        } else if (type == "Name") {
            this.setState({ Name: e.target.value }, () => {
                const { Name, Email } = this.state;

                if (Name.trim().length === 0) {
                    this.setState({
                        errorname: "用户名不可以为空",
                        invalidInput: true,
                    });
                    return;
                }

                if (emailRegex.test(Email) && Name.trim().length != 0) {
                    this.setState({ invalidInput: false });
                }
            });
        } else if (type == "Email") {
            this.setState({ Email: e.target.value }, () => {
                const { Email, Name } = this.state;

                if (!emailRegex.test(Email) && Email.trim().length > 0) {
                    this.setState({
                        erroremail: "错误电邮格式",
                        invalidInput: true,
                    });
                    return;
                }
                if (Email.trim().length === 0) {
                    this.setState({
                        erroremail: "邮箱不可以为空",
                        invalidInput: true,
                    });
                    return;
                }

                if (emailRegex.test(Email) && Name.trim().length != 0) {
                    this.setState({ invalidInput: false });
                }
            });
        } else if (type == "EmailForForgetUser") {
            this.setState({ EmailForForgetUser: e.target.value }, () => {
                const { EmailForForgetUser } = this.state;

                if (
                    !emailRegex.test(EmailForForgetUser) &&
                    EmailForForgetUser.trim().length > 0
                ) {
                    this.setState({
                        erroremailForForgetUser: "错误电邮格式",
                        invalidInputForForgetUser: true,
                    });
                    return;
                }
                if (EmailForForgetUser.trim().length === 0) {
                    this.setState({
                        erroremailForForgetUser: "邮箱不可以为空",
                        invalidInputForForgetUser: true,
                    });
                    return;
                }

                this.setState({ invalidInputForForgetUser: false });
            });
        } else {
            this.setState({ NEWPWD: e.target.value });
        }
    }

    showModal = (e) => {
        this.setState({
            visible: true,
            defaultkey: e,
        });
    };

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
        //	this.props.pwdModalClose('isShowPwdModal', false);
    };

    EmailForgetPassword() {
        this.setState({
            submitstatus: true,
        });

        let { Email, Name } = this.state;

        Name = Name.trim();

        if (Name == "") {
            this.setState({
                errorname: "用户名不可以为空",
            });
            return;
        }
        if (regSymbols.test(Email)) {
            this.setState({
                erroremail: "邮箱含有非法字符！",
            });
            return;
        }
        if (emailRegex.test(Email) == false) {
            this.setState({
                erroremail: "错误电邮格式",
            });
            return;
        }

        this.setState({
            loading: true,
        });

        let params = { username: Name, email: Email };

        post(`${ApiPort.POSTEmailForgetPassword}`, params)
            .then((res) => {
                console.log(res);

                this.setState({
                    loading: false,
                });

                if (res.message == "Username is not tally with email.") {
                    this.setState({ errorMessage: "用户名与邮箱不匹配" });
                    // message.error('用户名与邮箱不匹配');
                    return;
                }
                if (res.isSuccess == true) {
                    Modal.info({
                        className: "confirm-modal-of-public forgetPwdModal",
                        icon: <></>,
                        title: "提交成功",
                        centered: true,
                        okText: "确认",
                        zIndex: 2000,
                        content: (
                            <div
                                style={{ textAlign: "center" }}
                                dangerouslySetInnerHTML={{
                                    __html: "已将更新密码链接以邮件方式发送, 请至您的注册邮箱查收",
                                }}
                            />
                        ),
                    });
                    // message.success('重置密码邮件已发送至您的邮箱，请前往查收。');
                    return;
                }
                if (res.isSuccess == false) {
                    this.setState({ errorMessage: res.errors[0].description });
                    // message.error(res.errors[0].description);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        //Pushgtagdata('submit_forgetpassword');
    }

    Forgetuser() {
        this.setState({
            submitstatus: true,
        });
        if (regSymbols.test(this.state.EmailForForgetUser)) {
            this.setState({
                erroremailForForgetUser: "邮箱含有非法字符！",
            });
            return;
        }
        if (emailRegex.test(this.state.EmailForForgetUser) != true) {
            this.setState({
                erroremailForForgetUser: "错误电邮格式",
            });
            return;
        }
        this.setState({
            loading: true,
        });

        post(
            ApiPort.POSTForgetUsername +
                "email=" +
                this.state.EmailForForgetUser +
                "&redirectUrl=" +
                ApiPort.LOCAL_HOST
        )
            .then((res) => {
                console.log(res);

                if (res.isSuccess == true) {
                    Modal.info({
                        className: "confirm-modal-of-forgetuser",
                        icon: <></>,
                        title: "提交完成",
                        centered: true,
                        okText: "确认",
                        closable: true,
                        zIndex: 2000,
                        content: (
                            <div
                                style={{ textAlign: "center" }}
                                dangerouslySetInnerHTML={{
                                    __html: "用户名已成功发送至您的邮箱",
                                }}
                            />
                        ),
                    });
                    // message.success('您的用户名已发送至您的邮箱，请前往查收。');
                } else {
                    this.setState({
                        errorMessageForForgetUser: error.errors[0].description,
                    });
                    // message.error('您输入的用户名有误，请重新输入！');
                }
                this.setState({
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    errorMessageForForgetUser: error.errors[0].description,
                });
                console.log(error);
            });
        //Pushgtagdata('submit_forgetusername');
    }
    UpdateMemberPWD() {
        const { NEWPWD, OLDPWD } = this.state;
        const MemberData = {
            oldPassword: OLDPWD,
            newPassword: NEWPWD,
        };

        post(PUTMemberPassword, MemberData)
            .then((res) => {
                console.log(res);
                this.setState({
                    loading: false,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    onTabChange(key) {
        this.setState({
            Email: "",
            activeIndex: key,
            submitstatus: false,
        });
    }

    render() {
        const {
            loading,
            defaultkey,
            activeIndex,
            visible,
            Name,
            Email,
            EmailForForgetUser,
            errorname,
            erroremail,
            erroremailForForgetUser,
            submitstatus,
        } = this.state;
        return (
            <Modal
                title={activeIndex == 1 ? "忘记密码" : "忘记用户名"}
                visible={visible}
                onOk={this.handleCancel}
                onCancel={this.handleCancel}
                destroyOnClose={true}
                footer={null}
                className="modal-pubilc ForgotPwdModal"
                width={400}
                zIndex={2000}
            >
                <Tabs
                    defaultActiveKey={String(defaultkey)}
                    className="Forget-pwd-tabs tabs-modal-pubilc"
                    size="small"
                    tabPosition="top"
                    onChange={(activeKey) => {
                        this.onTabChange.call(this, activeKey);
                        Pushgtagdata(
                            activeKey === "1"
                                ? "ForgetPW_forgetPW"
                                : "ForgetUN_forgetUN"
                        );
                    }}
                >
                    <TabPane tab="忘记密码" key="1">
                        {this.state.errorMessage && (
                            <div className="invalid-input-error">
                                {this.state.errorMessage}
                            </div>
                        )}
                        <div className="IputBox">
                            <div>
                                <label>电子邮箱</label>
                                <Input
                                    type="email"
                                    value={Email}
                                    placeholder="请填写邮箱地址"
                                    className={
                                        (submitstatus || erroremail) &&
                                        (Email === "" ||
                                            !emailRegex.test(Email))
                                            ? "invalid-input-box-error"
                                            : ""
                                    }
                                    onChange={this.MemberChange.bind(
                                        this,
                                        "Email"
                                    )}
                                    onPressEnter={this.EmailForgetPassword.bind(
                                        this
                                    )}
                                    size="large"
                                />
                                {(submitstatus || erroremail) &&
                                    (Email === "" ||
                                        !emailRegex.test(Email)) && (
                                        <div className="error">
                                            {erroremail}
                                        </div>
                                    )}
                            </div>
                            <div>
                                <label>用户名</label>
                                <Input
                                    value={Name}
                                    placeholder="请填写用户名"
                                    className={
                                        (submitstatus || errorname) &&
                                        Name === ""
                                            ? "invalid-input-box-error"
                                            : ""
                                    }
                                    onChange={this.MemberChange.bind(
                                        this,
                                        "Name"
                                    )}
                                    onPressEnter={this.EmailForgetPassword.bind(
                                        this
                                    )}
                                    size="large"
                                />
                                {(submitstatus || errorname) && Name === "" && (
                                    <div className="error">{errorname}</div>
                                )}
                            </div>
                        </div>
                        <Button
                            disabled={this.state.invalidInput}
                            type="primary"
                            size="large"
                            onClick={() => {
                                this.EmailForgetPassword.call(this);
                                Pushgtagdata(
                                    "Navigation",
                                    "Submit",
                                    "Submit_ForgetPW"
                                );
                            }}
                            loading={loading}
                            style={{ width: "100%" }}
                        >
                            提交
                        </Button>
                    </TabPane>
                    <TabPane tab="忘记用户名" key="2">
                        {/* <p>请填写您的电子邮箱地址。此邮件地址将被用来发送账户重要信息和申请奖金时所必需的优惠代码。</p> */}
                        {this.state.errorMessageForForgetUser && (
                            <div className="invalid-input-error">
                                {this.state.errorMessageForForgetUser}
                            </div>
                        )}
                        <label>电子邮箱</label>
                        <div className="IputBox forgetuser-input">
                            <Input
                                type="email"
                                value={EmailForForgetUser}
                                placeholder="请填写邮箱地址"
                                className={
                                    (submitstatus || erroremailForForgetUser) &&
                                    (EmailForForgetUser === "" ||
                                        !emailRegex.test(EmailForForgetUser))
                                        ? "invalid-input-box-error"
                                        : ""
                                }
                                onChange={this.MemberChange.bind(
                                    this,
                                    "EmailForForgetUser"
                                )}
                                onPressEnter={this.Forgetuser.bind(this)}
                                size="large"
                            />
                            {(submitstatus || erroremailForForgetUser) &&
                                (EmailForForgetUser === "" ||
                                    !emailRegex.test(EmailForForgetUser)) && (
                                    <div className="error">
                                        {erroremailForForgetUser}
                                    </div>
                                )}
                        </div>
                        <Button
                            disabled={this.state.invalidInputForForgetUser}
                            type="primary"
                            size="large"
                            loading={loading}
                            onClick={() => {
                                this.Forgetuser.call(this);
                                Pushgtagdata(
                                    "Navigation",
                                    "Submit",
                                    "Submit_ForgetUN"
                                );
                            }}
                            // className="GreenBtn LoginGreenbtn"
                            style={{ width: "100%" }}
                        >
                            提交
                        </Button>
                        {/* <Button
							type="default"
							size="large"
							onClick={() => {
								this.setState({
									visible: false
								});
								Pushgtagdata('Back_forgetUN');
							}}
							style={{ width: '100%', marginTop: '10px' }}
						>
							返回
						</Button> */}
                    </TabPane>
                </Tabs>
            </Modal>
        );
    }
}
export default ForgotPwd;
