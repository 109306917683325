import React from "react";
import { encryptData } from "../../actions/cryptoUtils";

const getUrlParams = () => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search); // 獲取 URL 查詢字串
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    return params;
  }
  return {};
};

// HOC，封裝 iframe 的通用邏輯
const withIframeHandler = (WrappedComponent) => {
  return class WithIframeHandler extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showErrorModal: false,
        urlParams: {},
        isReady: false, // 設定未完成時顯示 Loading
        theme: "dark",
        token: null,
        encryptedToken: null,
        hostDomain: "",
      };
      this.iframeRef = React.createRef();
    }

    componentDidMount() {
      try {
        const memberToken = localStorage.getItem('access_token');
        const token = memberToken ? memberToken.replace(/"/g, '').split(' ')[1] : null;
        const sk = "f945954d6507556acdeab6a339848b22";
        const encryptedToken = token ? encryptData(token, sk) : null;
        const hostDomain = window.location.host;
        const urlParams = getUrlParams();
        this.setState({
          isReady: true,
          token: encodeURIComponent(encryptedToken),
          hostDomain: encodeURIComponent(hostDomain),
          urlParams,
        });
      } catch (error) {
        console.error("Initialization failed:", error);
        this.setState({ isReady: true, showErrorModal: true });
      }
    }

    render() {
      if (!this.state.isReady) {
        return (
          <div className="TextHeading">
            加载中...
          </div>
        );
      }
      return (
        <WrappedComponent
          {...this.props}
          iframeRef={this.iframeRef}
          theme={this.state.theme}
          platform={"desktop"}
          showErrorModal={this.state.showErrorModal}
          setShowErrorModal={(value) => this.setState({ showErrorModal: value })}
          urlParams={this.state.urlParams}
          token={this.state.token}
          hostDomain={this.state.hostDomain}
          onIframeLoad={this.handleIframeLoad}
        />
      );
    }
  };
};

export default withIframeHandler;