/*
 * @Author: Alan
 * @Date: 2023-07-04 12:27:14
 * @LastEditors: Alan
 * @LastEditTime: 2023-09-04 15:55:07
 * @Description: 紧急公告
 * @FilePath: /F1-M1-WEB-Code/components/Announcement/index.js
 */
import React from 'react';
import { Button, Modal, Radio, Checkbox } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { get } from '$ACTIONS/TlcRequest';
import { ApiPort } from '$ACTIONS/TLCAPI';
export default class Announcement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isChecked: false,
			value: '1',
			visible: false,
			data: []
		};
	}

	componentDidMount() {
		this.NewsList();
	}

	NewsList() {
		if (!localStorage.getItem('access_token')) {
			return;
		}
		if (this.props.optionType) {
			let tag = localStorage.getItem(this.props.optionType + '_' + localStorage.getItem('memberCode'));
			let reqConf = {IS_STILL_RETURN_JSONVALUE_IF_HTTP4xx5xx: true};
			get(ApiPort.getNews + `&optionType=${this.props.optionType}`, reqConf).then((res) => {
			try {
				if (!res || (res && Object.prototype.hasOwnProperty.call(res,'Http') && res.Http!=200)) {
					this.props.extraCancelFn && this.props.extraCancelFn(); // one of test-cases: member is entering one game, FE needs to close Announcement modal automaticly bcz !200 and load game directly
					return;
				}
				if (res && res.result && Array.isArray(res.result) && res.result.length) {
					res.result = res.result[0]; // just always pick 1st element
				} else {
					res.result = {};
				}
				if (res && res.isSuccess && JSON.stringify(res.result) != '{}' && !Array.isArray(res.result)) {
					let hasAnnoContent = res.result;
					let _visible = !tag;
					if (hasAnnoContent) {
					this.setState({
						data: res.result,
						visible: _visible
					});
					}

					if (!hasAnnoContent || !_visible) { // MUST consider two scenario: There might be no product announcement || user set as no-reminder again
						if (this.props.autoExecuteCancelFnWhenLoadedAndNotVisible) {
							this.props.extraCancelFn && this.props.extraCancelFn();
						}
					}
				} else {
					this.props.extraCancelFn && this.props.extraCancelFn(); // always execute extraCancelFn especially for game opening.
				}
			} catch {
				this.props.extraCancelFn && this.props.extraCancelFn(); // always execute extraCancelFn especially for game opening.
			}
			});
		}
	}

	onChangeRadio = () => {
		this.setState({
			isChecked: !this.state.isChecked
		});
		if (this.state.isChecked) {
			localStorage.removeItem(this.props.optionType + '_' + localStorage.getItem('memberCode'));
		} else {
			localStorage.setItem(this.props.optionType + '_' + localStorage.getItem('memberCode'), true);
		}
		Pushgtagpiwikurl('deposit_announcement_popup', 'Deposit Announcement Popup')
		PushgtagdataWithEventValue('Deposit', 'Do Not Show Popup', 'Deposit_Popup_C_Notshow')
	};

	handleCancel = () => {
		if (this.props.extraCancelFn) { // 743UAT slide10
			this.props.extraCancelFn();
		}
		this.setState({ isChecked: !this.state.isChecked, visible: false });
	};
	render() {
		const { isChecked, value, data, visible } = this.state;

		return (
			<React.Fragment>
				<Modal
					title="重要公告"
					centered={true}
					visible={visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={null}
					width={400}
					// closeIcon={<Icon type="close-circle" style={{ fontSize: '30px', color: '#CCCCCC' }} />}
					maskClosable={false}
				>
					<div>
						<br />
						<center className="title">
							<h3>{data && data.topic}</h3>
						</center>
						<div
							className="content"
							dangerouslySetInnerHTML={{
								__html: data && data.content
							}}
						/>
						<br />
						<div className="footer">
							<center className="checkbox-button-container">
								<Checkbox checked={isChecked} onChange={this.onChangeRadio}>
									<Button
										type={isChecked ? 'primary' : 'default'}
										icon={isChecked ? <CheckOutlined /> : <CloseOutlined />}
									/>
								</Checkbox>
								<span>不再显示</span>
							</center>
							<br />
							<Button size="large" block type="primary" onClick={() => {
								global.PopUpLiveChat()
								Pushgtagpiwikurl('deposit_announcement_popup', 'Deposit Announcement Popup')
								PushgtagdataWithEventValue('Deposit', 'Contact CS', 'Deposit_Popup_C_CS')
							}}>
								联系在线客服
							</Button>
						</div>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}
